import * as React from "react";
import Toolbar from "@warda/library-ui/core/Toolbar";
import Text from "@warda/library-ui/core/Text";
import List from "@warda/library-ui/core/List";
import Divider from "@warda/library-ui/core/Divider";
import Icon from "@warda/library-ui/core/Icon";
import { ACT_MODAL } from "./reducer";
import { CtxCtlgs } from "../contexts";
import ListItem from "@warda/library-ui/core/ListItem";
import { WIDTH_LANGS } from "./utils";
import useStyles from "./useStylesList";
import { SelectType } from "@warda/library-ui/core/Checkbox";
const ListLangs = ({ dispatchModal, slcCtlg, slcLangs, readOnly, }) => {
    const classes = useStyles({});
    const catalogs = React.useContext(CtxCtlgs);
    const ctlg = catalogs.find((a) => a.id === slcCtlg);
    const selected = React.useMemo(() => new Set(slcLangs), [slcLangs]);
    const listitems = React.useMemo(() => {
        if (!ctlg)
            return [];
        return ctlg.languages.map((l) => ({
            id: l,
            label: l.toLocaleUpperCase(),
            selected: selected.has(l),
        }));
    }, [ctlg, selected]);
    const selectedAll = !listitems.some((a) => !selected.has(a.id));
    const onClick = React.useCallback((e, id) => {
        dispatchModal({ type: ACT_MODAL.LANGS_SELECTED, id });
    }, [dispatchModal]);
    const onSelectAll = React.useCallback(() => {
        dispatchModal({
            type: ACT_MODAL.LANGS_SLC_ALL,
            ids: listitems.map((a) => a.id),
        });
    }, [dispatchModal, listitems]);
    if (!ctlg)
        return null;
    return (React.createElement("div", { className: classes.panel, style: { width: WIDTH_LANGS } },
        React.createElement(Toolbar, { className: classes.toolbar },
            React.createElement(Icon, { style: { marginRight: 4 }, children: "flag" }),
            React.createElement(Text, { weight: "bolder", children: "Select Languages" })),
        React.createElement(List, { style: { paddingTop: 0 } },
            React.createElement(ListItem, { className: classes.listitem, id: "all", label: "Select All", selected: selectedAll, selectType: SelectType.CHECK, onClick: onSelectAll, disabled: readOnly })),
        React.createElement(Divider, { className: classes.divider }),
        React.createElement(List, { className: classes.list }, listitems.map(({ id, label, selected }) => (React.createElement(ListItem, { className: classes.listitem, key: id, id: id, label: label, selected: selected, selectType: SelectType.CHECK, onClick: onClick, disabled: readOnly }))))));
};
export default ListLangs;
