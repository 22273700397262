import { AREA_MEDIA_LIBRARY } from "../../constants";
export var AreaML;
(function (AreaML) {
    AreaML["MYFILES"] = "myfiles";
    AreaML["TOUCHPOINTS_LIST"] = "touchpoints";
    AreaML["TOUCHPOINT"] = "touchpoint";
    AreaML["SHARED_WITH_ME"] = "shared-with-me";
    AreaML["CORPORATE"] = "corporate";
    AreaML["STAGING"] = "staging";
    AreaML["SHAREPAGE"] = "sharepage";
})(AreaML || (AreaML = {}));
export const AreaML_ICON = {
    [AreaML.MYFILES]: "folder",
    [AreaML.STAGING]: "dataset",
    [AreaML.TOUCHPOINTS_LIST]: "cast_connected",
    [AreaML.SHARED_WITH_ME]: "share",
    [AreaML.CORPORATE]: "business_center",
};
export const AreaML_TEXT = {
    [AreaML.MYFILES]: "My Files",
    [AreaML.STAGING]: "Staging Area",
    [AreaML.TOUCHPOINTS_LIST]: "Brand Touchpoints",
    [AreaML.SHARED_WITH_ME]: "Shared with me",
    [AreaML.CORPORATE]: "Corporate",
};
export const NO_VALUE = "-1";
export const KEYPAR_AREA = "area";
export const KEYPAR_PATH = "path";
export const decomposePath = (path) => {
    const [hrch, prnt] = path.split("_");
    if (!hrch || hrch === NO_VALUE || !prnt || prnt === NO_VALUE) {
        return [undefined, undefined];
    }
    return [String(hrch), Number(prnt)];
};
export const composePath = (hrch, prnt) => {
    if (hrch === "" || hrch === undefined || prnt === undefined)
        return "";
    return hrch + "_" + prnt;
};
export const getQueryParams = () => {
    const query = new URLSearchParams(window.location.search);
    const path = query.get(KEYPAR_PATH) || "";
    return {
        area: query.get(KEYPAR_AREA),
        hrch: decomposePath(path)[0],
        prnt: decomposePath(path)[1],
    };
};
export const alignSearchString = ({ history, search, area, }) => {
    const sectionsSet = new Set(Object.values(AreaML));
    if (!area || !sectionsSet.has(area)) {
        updateSearchString({
            history,
            search,
            area: null,
            path: null,
        });
    }
};
export const updateSearchString = ({ history, search, area, path, }) => {
    const query = new URLSearchParams(search);
    const setQuery = (key, value, fallback) => {
        const newValue = value !== undefined
            ? value !== null
                ? value
                : fallback
            : query.get(key) || fallback;
        query.set(key, newValue);
    };
    setQuery(KEYPAR_AREA, area, AreaML.MYFILES);
    setQuery(KEYPAR_PATH, path, NO_VALUE);
    const newSearchString = `/${AREA_MEDIA_LIBRARY}/?${query.toString()}`;
    history.push(newSearchString);
};
