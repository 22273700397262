import * as React from "react";
import Dialog from "./Dialog";
import { ACT_VPORT } from "./reducer";
import Text from "@warda/library-ui/core/Text";
import { CtxSetSnackbar, CtxDispatchViewport } from "./contexts";
import { Initialize, Severity } from "@warda/library-ui/interfaces";
import getErrorMsg from "../../utils/getErrorMsg";
import LinkSelection from "../AreaMediaLibrary/AreaFiles/LinkSelection";
import getIdsFile from "../AreaMediaLibrary/AreaFiles/utils/getIdsFile";
import { getTheme } from "@warda/library-ui/theme";
import { anonymousDownloadMassive } from "../../api/fetchesApiAnonymous";
import { v4 as uuidv4 } from "uuid";
import { getWebSocketConnectionId } from "../webSocket";
const DialogConfirmDownload = ({ open, files, filesSlcId, pageHrchId, }) => {
    const setSnackbar = React.useContext(CtxSetSnackbar);
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const [initConfirm, setInit] = React.useState(Initialize.NONE);
    const cbOnApply = React.useCallback(() => {
        setInit(Initialize.START);
    }, []);
    const cbOnClose = React.useCallback(() => {
        dispatchViewport({ type: ACT_VPORT.DIALOG_DOWNLOAD, open: false });
    }, [dispatchViewport]);
    const loading = initConfirm === Initialize.START || initConfirm === Initialize.LOADING;
    React.useEffect(() => {
        if (!open)
            setInit(Initialize.NONE);
    }, [open]);
    React.useEffect(() => {
        if (initConfirm === Initialize.START) {
            (async () => {
                try {
                    setInit(Initialize.LOADING);
                    const { idsFolder, idsMedia } = getIdsFile(files, filesSlcId);
                    if (filesSlcId.length === 1 && idsMedia.length === 1) {
                        dispatchViewport({ type: ACT_VPORT.DOWNLOAD_SINGLE_MEDIA });
                        setSnackbar(Severity.INFO, "Download media");
                    }
                    else {
                        const correlationId = uuidv4();
                        const { success, message } = await anonymousDownloadMassive({
                            correlationId,
                            connectionId: getWebSocketConnectionId(),
                            hrch: pageHrchId,
                            idsFolder,
                            idsMedia,
                        });
                        if (!success)
                            throw message;
                        dispatchViewport({
                            type: ACT_VPORT.DOWNLOADS_TO_ADD,
                            payload: {
                                correlationId,
                                filename: `${correlationId}.zip`,
                            },
                        });
                        if (filesSlcId.length > 1) {
                            setSnackbar(Severity.INFO, "Preparing zip download");
                        }
                    }
                    cbOnClose();
                }
                catch (err) {
                    const errMsg = await getErrorMsg(err);
                    setSnackbar(Severity.WARN, errMsg);
                    setInit(Initialize.NONE);
                }
            })();
        }
    }, [
        cbOnClose,
        dispatchViewport,
        files,
        filesSlcId,
        initConfirm,
        pageHrchId,
        setSnackbar,
    ]);
    return (React.createElement(Dialog, { open: open, title: "Confirm download", loading: loading, onClose: cbOnClose, style: { padding: "5px 15px" }, buttons: [
            {
                label: "Download",
                onClick: cbOnApply,
            },
        ] },
        React.createElement(Text, { children: "Are you sure to download the current selection?" }),
        React.createElement(LinkSelection, { color: getTheme().colors.theme2, style: { padding: 0 }, files: files, filesSlcId: filesSlcId })));
};
export default DialogConfirmDownload;
