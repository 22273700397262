import * as React from "react";
import Text from "@warda/library-ui/core/Text";
import Placeholder from "@warda/library-ui/core/Placeholder";
import useStyles from "../useStylesSelect";
import ListTitle from "../ListTitle";
import SelectView from "./SelectView";
import FieldSearch from "@warda/library-ui/core/FieldSearch";
import List from "@warda/library-ui/core/List";
const filterItems = (items, inputSearch) => items.filter(({ view }) => {
    const i = inputSearch.toLocaleUpperCase();
    return view.toLocaleUpperCase().includes(i);
});
const SelectViews = ({ slcCtlgs, slcViews, onChange, onSearch, inputSearch, readOnly, }) => {
    const classes = useStyles({});
    const groups = slcCtlgs.reduce((acc, catalog) => {
        const { id, selected, displayName } = catalog;
        if (selected) {
            acc.push({
                id,
                title: displayName,
                items: slcViews.filter((v) => v.catalog === id),
            });
        }
        return acc;
    }, []);
    const selectallCount = groups.reduce((a, g) => a + g.items.length, 0);
    const selectallCheck = !groups.find(({ items }) => !!items.find((i) => !i.selected));
    const onSelect = React.useCallback((id) => {
        let newItem;
        const newItems = slcViews.map((item) => {
            if (id === item.id) {
                newItem = {
                    ...item,
                    selected: !item.selected,
                };
                return newItem;
            }
            return item;
        });
        onChange([newItem], newItems);
    }, [slcViews, onChange]);
    const onSelectAll = React.useCallback(() => {
        const newItems = slcViews.map((item) => ({
            ...item,
            selected: !selectallCheck,
        }));
        onChange(newItems, newItems);
    }, [slcViews, onChange, selectallCheck]);
    const placeholder = !slcCtlgs.find(({ selected }) => selected);
    return (React.createElement("div", { className: classes.listContainer },
        React.createElement(ListTitle, { icon: "photo", title: "Views", titleInfo: "Among selected catalogs to which views do you want to apply the Ready status?", selectAllCheck: selectallCheck, selectAllCount: selectallCount, selectAllOnClick: onSelectAll, readOnly: readOnly }),
        placeholder ? (React.createElement(Placeholder, { label: "Please select at least one catalog" })) : (React.createElement(React.Fragment, null,
            React.createElement(FieldSearch, { style: { width: "auto" }, value: inputSearch, onChange: onSearch }),
            React.createElement(List, { className: classes.listContainerScroll }, groups
                .filter(({ items }) => {
                return !!filterItems(items, inputSearch).length;
            })
                .map(({ id, title, items }) => (React.createElement(React.Fragment, { key: id },
                React.createElement(Text, { size: 2, weight: "bolder", className: classes.listContainerGroupTitle, children: title }),
                filterItems(items, inputSearch).map(({ id, view, selected, category }) => (React.createElement(SelectView, { key: id, id: id, label: view, labelSub: category === "DEFAULT" ? "" : category, selected: selected, onClick: onSelect, className: classes.listItemNested, readOnly: readOnly })))))))))));
};
export default SelectViews;
