import * as React from "react";
import { FieldText } from "@warda/library-ui/core/Field";
const Field = ({ icon, label, onChange, onEnter, autoComplete, value, readOnly, inputType, inputName, }) => {
    const onKeyPress = React.useCallback((key) => {
        if (key === "Enter" && onEnter)
            onEnter();
    }, [onEnter]);
    return (React.createElement(FieldText, { label: label, value: value, onChange: onChange, inputType: inputType, inputName: inputName, autoComplete: autoComplete, adornmentIcon: icon, onKeyPress: onKeyPress, readOnly: readOnly, debounce: 0 }));
};
export default Field;
