import * as React from "react";
import Text from "@warda/library-ui/core/Text";
import Card from "@warda/library-ui/core/Card";
import { ACTIONS, reducerInitState, reducer } from "./reducer";
import Field from "../component/Field";
import Toolbar from "@warda/library-ui/core/Toolbar";
import useStyles from "../component/useStyles";
import Btn from "@warda/library-ui/core/Btn";
import CircularProgress from "@warda/library-ui/core/CircularProgress";
import { getTheme } from "@warda/library-ui/theme";
import { Initialize } from "@warda/library-ui/interfaces";
import setThemeDefault from "../../../utils/setThemeDefault";
const Login = ({ title, backgroundNode, onLogin, onResetPassword }) => {
    document.title = `${title} Login`;
    const classes = useStyles();
    const [state, dispatch] = React.useReducer(reducer, reducerInitState);
    const { initConfirm, username, password, tenant } = state;
    const loading = initConfirm === Initialize.START || initConfirm === Initialize.LOADING;
    const disabled = !username || !password || !tenant;
    const cbOnLoginStart = React.useCallback(() => {
        dispatch({ type: ACTIONS.INIT_CONFIRM_START });
    }, []);
    const cbOnLoginFail = React.useCallback(() => {
        dispatch({ type: ACTIONS.INIT_CONFIRM_FAIL });
    }, []);
    const cbOnChangeTent = React.useCallback((value) => {
        dispatch({ type: ACTIONS.SET_TENT, value });
    }, []);
    const cbOnChangeUser = React.useCallback((value) => {
        dispatch({ type: ACTIONS.SET_USER, value });
    }, []);
    const cbOnChangePass = React.useCallback((value) => {
        dispatch({ type: ACTIONS.SET_PASS, value });
    }, []);
    const cbOnEnter = React.useCallback(() => {
        if (!disabled) {
            cbOnLoginStart();
            document.documentElement.blur();
        }
    }, [cbOnLoginStart, disabled]);
    React.useEffect(() => {
        setThemeDefault();
    }, []);
    React.useEffect(() => {
        (async () => {
            if (initConfirm === Initialize.START) {
                dispatch({ type: ACTIONS.INIT_CONFIRM_LOADING });
                await onLogin(username, password, tenant, cbOnLoginFail);
            }
        })();
    }, [initConfirm, onLogin, cbOnLoginFail, password, tenant, username]);
    return (React.createElement("form", { className: classes.root },
        React.createElement(Card, { className: classes.paper, elevation: 1 },
            React.createElement(Text, { weight: "lighter", className: classes.title, children: title }),
            React.createElement(Field, { autoComplete: "organization", inputType: "text", inputName: "organization", icon: "domain", label: "Company", value: tenant, onEnter: cbOnEnter, onChange: cbOnChangeTent, readOnly: loading }),
            React.createElement(Field, { autoComplete: "username email", inputType: "text", inputName: "username", icon: "person", label: "Email", value: username, onEnter: cbOnEnter, onChange: cbOnChangeUser, readOnly: loading }),
            React.createElement(Field, { autoComplete: "current-password", inputType: "password", inputName: "password", icon: "lock", label: "Password", value: password, onEnter: cbOnEnter, onChange: cbOnChangePass, readOnly: loading }),
            React.createElement(Toolbar, { className: classes.toolbar },
                React.createElement(Btn, { color: getTheme().colors.theme1, onClick: onResetPassword, label: "Forgotten credentials?", disabled: loading }),
                React.createElement("div", { style: { flex: 1 } }),
                !loading ? null : (React.createElement(CircularProgress, { size: 20, color: getTheme().colors.theme2, style: { margin: "0 10px" } })),
                initConfirm === Initialize.FAIL ? (React.createElement(Btn, { style: { padding: "0 30px" }, variant: "bold", color: getTheme().colors.msgFail, onClick: cbOnLoginStart, label: "Invalid credentials!" })) : (React.createElement(Btn, { style: { padding: "0 30px" }, variant: "bold", color: getTheme().colors.theme1, disabled: disabled || loading, onClick: cbOnLoginStart, label: "LOGIN" })))),
        backgroundNode));
};
export default Login;
