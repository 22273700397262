import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import { createUseStyles } from "react-jss";
import isEmpty from "lodash-es/isEmpty";
import { Severity, Initialize } from "@warda/library-ui/interfaces";
import { KEY_ENTITY_ID, KEY_ENTITY_TYPE, KEY_MEDIA, KEY_VIEW_CHECK, KEY_VIEW_DATA, KEY_VIEW_STATUS, } from "../../../constantsKey";
import { multiReady } from "../../../api/fetchesApi";
import getReadySets from "./utils/getReadySets";
import reducer, { reducerInitState, ACTION } from "./reducer";
import ListDivider from "./ListDivider";
import Footer from "./Footer";
import SelectCatalogs from "./SelectCatalogs";
import SelectViews from "./SelectViews";
import Btn from "@warda/library-ui/core/Btn";
import { CtxSetSnackbar } from "../../contexts";
import Modal from "@warda/library-ui/core/Modal";
import Text from "@warda/library-ui/core/Text";
import { CtxCtlgs, CtxViews } from "../contexts";
import ModalErrorMultiLevel from "../ModalErrorMultiLevel";
import getProducts from "../getProducts";
import getErrorMsg from "../../../utils/getErrorMsg";
import { ERROR_INTERNALSERVER, ERROR_TOO_MANY_ITEMS } from "../../../constants";
import { MultiReadyOp, MultiReadyTarget, } from "../ToolbarHeader/BtnReadys/BtnReadys";
import getMultiReadyCtlgs from "./utils/getMultiReadyCtlgs";
import getMultiReadyViews from "./utils/getMultiReadyViews";
const errNoCtlg = "This action can’t be done because there aren’t catalogs assigned to the current selection of products";
const useStyles = createUseStyles({
    dialogHeader: {
        padding: "10px 0 0",
        height: 90,
        display: "flex",
        flexDirection: "column",
        alignItems: "self-start",
    },
    dialogContent: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        borderRadius: getTheme().borderRadius,
        border: `1px solid ${getTheme().colors.grayBorder}`,
        padding: "0 10px",
        minWidth: 800,
        marginBottom: 10,
    },
});
const getConfirmDisabled = (readySets, items, slcCtlgs) => {
    const thereAreMedias = (items) => !!items.find((item) => !isEmpty(item[KEY_MEDIA] || []));
    const thereAreCatalogSelected = (slcCtlgs) => !!slcCtlgs.find((catalog) => catalog.selected);
    const thereAreViewStatus = (items) => !!items.find((item) => !isEmpty(item[KEY_VIEW_DATA] || []));
    return (isEmpty(readySets) ||
        !thereAreCatalogSelected(slcCtlgs) ||
        !thereAreMedias(items) ||
        !thereAreViewStatus(items));
};
const getTitleHelp = (multiReadyTarget, multiReadyOp) => {
    const desc = [
        `Please select which views' catalogs you want to ${multiReadyOp == MultiReadyOp.Set ? "set" : "unset"} Ready.`,
        `Ready status will be applied only if last ${multiReadyTarget == MultiReadyTarget.Media ? "media" : "document"} uploaded is:`,
    ];
    const types = multiReadyTarget == MultiReadyTarget.Media
        ? [`- video`, `- post-production media`]
        : [`- document`];
    return [...desc, ...types];
};
const ModalMediaReady = ({ open, onClose, itemsIdSelected, multiReadyTarget, multiReadyOp, }) => {
    const tntCtlgs = React.useContext(CtxCtlgs);
    const tntViews = React.useContext(CtxViews); // Perchè non filtro rispetto a multiReadyTarget?
    const setSnackbar = React.useContext(CtxSetSnackbar);
    const classes = useStyles({});
    const [state, dispatch] = React.useReducer(reducer, reducerInitState);
    const { initModal, initConfirm, slcCtlgs, slcViews, error, items, inputCatalogs, inputViews, skipItemsReady, skipItemsReview, onlyItemsChecked, } = state;
    const onClickConfirm = React.useCallback(() => {
        dispatch({ type: ACTION.INIT_CONFIRM_STARTED });
    }, []);
    const onSelectCatalogs = React.useCallback((changedCatalogs, newCatalogs) => {
        dispatch({
            type: ACTION.CHANGE_CATALOGS,
            multiReadyOp,
            changedCatalogs,
            newCatalogs,
        });
    }, [multiReadyOp]);
    const onSelectViews = React.useCallback((changedViews, newViews) => {
        dispatch({
            type: ACTION.CHANGE_VIEWS,
            multiReadyOp,
            changedViews,
            newViews,
        });
    }, [multiReadyOp]);
    const onToggleSkipReviewItems = React.useCallback(() => {
        dispatch({ type: ACTION.TOGGLE_SKIP_REVIEW });
    }, []);
    const onToggleSkipReadyItems = React.useCallback(() => {
        dispatch({ type: ACTION.TOGGLE_SKIP_READY });
    }, []);
    const onToggleSkipCheckedItems = React.useCallback(() => {
        dispatch({ type: ACTION.TOGGLE_SKIP_CHECKED });
    }, []);
    const onSearchCatalogs = React.useCallback((value) => {
        dispatch({ type: ACTION.INPUT_CATALOGS, value });
    }, []);
    const onSearchViews = React.useCallback((value) => {
        dispatch({ type: ACTION.INPUT_VIEWS, value });
    }, []);
    const { readySets, countItemsToApply, countItemsInReady, countItemsInReview, countItemsInChecked, } = getReadySets({
        multiReadyOp,
        multiReadyTarget,
        items,
        slcCtlgs,
        slcViews,
        skipItemsReady,
        skipItemsReview,
        onlyItemsChecked,
    });
    const confirmDisabled = getConfirmDisabled(readySets, items, slcCtlgs);
    const errorText = error || (!slcCtlgs.length ? errNoCtlg : "") || "";
    const itemsTypeSize = new Set(items.map((a) => a[KEY_ENTITY_TYPE])).size;
    const loading = initModal === Initialize.START || initModal === Initialize.LOADING;
    const spinner = initConfirm === Initialize.START || initConfirm === Initialize.LOADING;
    React.useEffect(() => {
        if (open && itemsIdSelected.length !== 0) {
            dispatch({ type: ACTION.INIT_MODAL_START });
        }
    }, [itemsIdSelected.length, open]);
    React.useEffect(() => {
        if (!open && initModal !== Initialize.START) {
            dispatch({ type: ACTION.RESET });
        }
    }, [initModal, open]);
    // save the new item's catalogs into the reducer
    React.useEffect(() => {
        (async () => {
            if (initModal === Initialize.START) {
                try {
                    dispatch({ type: ACTION.INIT_MODAL_LOADING });
                    const items = await getProducts({
                        ids: itemsIdSelected,
                        columns: [],
                        categories: [],
                        source: [
                            KEY_ENTITY_ID,
                            KEY_ENTITY_TYPE,
                            KEY_MEDIA,
                            KEY_VIEW_DATA,
                            KEY_VIEW_STATUS,
                            KEY_VIEW_CHECK,
                        ],
                    });
                    const slcCtlgs = getMultiReadyCtlgs({
                        multiReadyOp,
                        multiReadyTarget,
                        items,
                        tntCtlgs,
                        tntViews,
                    });
                    const slcViews = getMultiReadyViews(items, multiReadyTarget);
                    dispatch({
                        type: ACTION.INIT_MODAL_SUCC,
                        multiReadyTarget,
                        multiReadyOp,
                        slcCtlgs,
                        slcViews,
                        items,
                    });
                }
                catch (err) {
                    const errorMsg = await getErrorMsg(err);
                    const error = errorMsg !== ERROR_INTERNALSERVER ? errorMsg : ERROR_TOO_MANY_ITEMS;
                    dispatch({ type: ACTION.INIT_MODAL_FAIL, error });
                }
            }
        })();
    }, [
        initModal,
        itemsIdSelected,
        tntCtlgs,
        tntViews,
        multiReadyTarget,
        multiReadyOp,
    ]);
    // when clicked CONFIRM, fetch data
    React.useEffect(() => {
        (async () => {
            if (initConfirm === Initialize.START) {
                dispatch({ type: ACTION.INIT_CONFIRM_LOADING });
                try {
                    await multiReady(readySets, multiReadyTarget, multiReadyOp);
                    setSnackbar(Severity.SUCC, "Ready Status will be applied soon");
                }
                catch (err) {
                    console.warn(`Massive Ready ${multiReadyOp} for  ${multiReadyTarget} failed! `, err);
                    setSnackbar(Severity.WARN, "Unable to apply Ready Status, please refresh and retry");
                }
                dispatch({ type: ACTION.INIT_CONFIRM_STOPPED });
                onClose();
            }
        })();
    }, [
        initConfirm,
        onClose,
        readySets,
        setSnackbar,
        multiReadyTarget,
        multiReadyOp,
    ]);
    if (!!errorText) {
        return (React.createElement(Modal, { loading: loading, open: open, onClose: onClose, title: "Unable to process the request", content: React.createElement(Text, { children: errorText }), actions: React.createElement(React.Fragment, null,
                React.createElement("div", { style: { flex: 1 } }),
                React.createElement(Btn, { color: getTheme().colors.msgSucc, variant: "bold", label: "OK, I UNDERSTAND", onClick: onClose })) }));
    }
    const title = multiReadyOp + " Ready - " + multiReadyTarget;
    if (open && itemsTypeSize > 1) {
        return (React.createElement(ModalErrorMultiLevel, { title: title, loading: loading, onClose: onClose }));
    }
    return (React.createElement(Modal, { open: open, onClose: onClose, loading: loading, title: title, titleHelp: getTitleHelp(multiReadyTarget, multiReadyOp), content: React.createElement("div", { className: classes.dialogContent },
            tntCtlgs.length === 1 ? null : (React.createElement(React.Fragment, null,
                React.createElement(SelectCatalogs, { multiReadyOp: multiReadyOp, multiReadyTarget: multiReadyTarget, slcCtlgs: slcCtlgs, slcViews: slcViews, onChange: onSelectCatalogs, onSearch: onSearchCatalogs, inputSearch: inputCatalogs, readOnly: spinner }),
                React.createElement(ListDivider, null))),
            React.createElement(SelectViews, { slcCtlgs: slcCtlgs, slcViews: slcViews, onChange: onSelectViews, onSearch: onSearchViews, inputSearch: inputViews, readOnly: spinner })), actions: React.createElement(Footer, { loading: spinner, multiReadyOp: multiReadyOp, 
            //
            btnItemsCheckedOnToggle: onToggleSkipCheckedItems, btnItemsCheckedSelected: onlyItemsChecked, btnItemsCheckedCount: countItemsInChecked, 
            //
            btnItemsReviewOnToggle: onToggleSkipReviewItems, btnItemsReviewSelected: skipItemsReview, btnItemsReviewCount: countItemsInReview, 
            //
            btnItemsReadyOnToggle: onToggleSkipReadyItems, btnItemsReadySelected: skipItemsReady, btnItemsReadyCount: countItemsInReady, 
            //
            itemsToApplyCount: countItemsToApply, confirmDisabled: confirmDisabled, onConfirm: onClickConfirm, onCancel: onClose }) }));
};
export default ModalMediaReady;
