import * as React from "react";
import Popover from "@warda/library-ui/core/Popover";
import Divider from "@warda/library-ui/core/Divider";
import List from "@warda/library-ui/core/List";
import WindowSectionsItem from "./WindowSectionsItem";
const WindowSections = ({ anchorEl, open, onClose, sectionId, sections, }) => {
    const sectTypes = Array.from(new Set(sections.map((a) => a.type)));
    return (React.createElement(React.Fragment, null,
        React.createElement(Popover, { anchorEl: anchorEl, open: open, onClose: onClose, style: { minWidth: 220 } },
            React.createElement(List, null, sectTypes.map((type, index) => {
                const sect = sections.filter((a) => a.type === type);
                return (React.createElement(React.Fragment, { key: type },
                    sectTypes.length === 1 ||
                        index === 0 ||
                        !sect.length ? null : (React.createElement(Divider, { style: { margin: 5 } })),
                    sect.map((a) => (React.createElement(WindowSectionsItem, { ...a, key: a.id, sectionId: sectionId, onClose: onClose })))));
            })))));
};
export default WindowSections;
