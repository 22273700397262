import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import { createUseStyles } from "react-jss";
import Btn from "@warda/library-ui/core/Btn";
import { Initialize, Severity, DATE_FORMAT, } from "@warda/library-ui/interfaces";
import reducer, { reducerInitState, ACT_MODAL, MAX_SELECTIONS, } from "./reducer";
import { CtxSetSnackbar } from "../../contexts";
import { multiReady } from "../../../api/fetchesApi";
import Modal from "@warda/library-ui/core/Modal";
import TextLoading from "@warda/library-ui/core/TextLoading";
import { CtxCtlgs, CtxCtgrs, CtxColumns } from "../contexts";
import LinkSummaryReady from "./LinkSummaryReady";
import { KEY_ENTITY_TYPE, JsonstoreAction, JsonstoreContext, KEY_READY_ATTRS, } from "../../../constantsKey";
import ModalErrorMultiLevel from "../ModalErrorMultiLevel";
import { getReadySets, canItWork, HEIGHT, WIDTH_ATTRS, WIDTH_LANGS, WIDTH_CTLGS, } from "./utils";
import ListCtlgs from "./ListCtlgs";
import ListLangs from "./ListLangs";
import ListAttrs from "./ListAttrs";
import Placeholder from "@warda/library-ui/core/Placeholder";
import Text from "@warda/library-ui/core/Text";
import ListSavedSelections from "./ListSavedSelections";
import Toolbar from "@warda/library-ui/core/Toolbar";
import Divider from "@warda/library-ui/core/Divider";
import ListItem from "@warda/library-ui/core/ListItem";
import { SelectType } from "@warda/library-ui/core/Checkbox";
import { addJSPref, delJSPref, getJSPref, putJSPref, } from "../../../api/fetchesApiJsonStore";
import CircularProgress from "@warda/library-ui/core/CircularProgress";
import getErrorMsg from "../../../utils/getErrorMsg";
import * as moment from "moment";
import getProducts from "../getProducts";
import { MultiReadyOp, MultiReadyTarget, } from "../ToolbarHeader/BtnReadys/BtnReadys";
const title = "Set Ready - Attributes";
const JsContext = JsonstoreContext.productsReadyAttrs;
const ITEMS_TOGET = 25;
const WIDTH = WIDTH_CTLGS + WIDTH_LANGS + WIDTH_ATTRS;
const useStyles = createUseStyles({
    panel: {
        position: "relative",
        border: `1px solid ${getTheme().colors.grayBorder}`,
        borderRadius: getTheme().borderRadius,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        width: WIDTH,
        minWidth: WIDTH,
        maxWidth: WIDTH,
        height: HEIGHT,
        minHeight: HEIGHT,
        maxHeight: HEIGHT,
        overflow: "hidden",
    },
    content: {
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        minHeight: 0,
        flex: 1,
    },
    saveSlcToolbar: {
        backgroundColor: getTheme().colors.grayDrawer,
        padding: "0 10px",
    },
    saveSlcButton: {
        borderRadius: getTheme().borderRadius,
        flex: 0,
        backgroundColor: "transparent",
    },
});
const ModalAttributesReady = ({ open, onClose, itemsIdSelected, slcCtlg, slcLang, }) => {
    const classes = useStyles({});
    const columns = React.useContext(CtxColumns);
    const catalogs = React.useContext(CtxCtlgs);
    const categories = React.useContext(CtxCtgrs);
    const setSnackbar = React.useContext(CtxSetSnackbar);
    const [stateModal, dispatchModal] = React.useReducer(reducer, reducerInitState);
    const { initModal, initConfirm, initGetData, items, error, ctlgsSelected, langsSelected, attrsSelected, attrsInputted, saveSelections, openSelections, selections, selectionsActn, selectionsInit, selectionDraft, } = stateModal;
    const ctlg = catalogs.find((c) => c.id === ctlgsSelected[0]);
    const langs = !ctlg ? [] : ctlg.languages;
    const onSelectionsOpen = React.useCallback(() => {
        dispatchModal({ type: ACT_MODAL.SELECTIONS_LIST_OPEN });
    }, []);
    const onSelectionsSave = React.useCallback(() => {
        dispatchModal({ type: ACT_MODAL.SELECTIONS_LIST_SAVE });
    }, []);
    const onConfirm = React.useCallback(() => {
        dispatchModal({ type: ACT_MODAL.INIT_CONFIRM_STARTED });
    }, []);
    const readySets = getReadySets({
        columns,
        items,
        slcAttrs: attrsSelected,
        slcCtlgs: ctlgsSelected,
        slcLangs: langsSelected,
    });
    const itWork = canItWork(columns, catalogs, items);
    const checkboxDisabled = selections.length > MAX_SELECTIONS;
    const loading = initModal === Initialize.START || initModal === Initialize.LOADING;
    const spinner = initConfirm === Initialize.START || initConfirm === Initialize.LOADING;
    const getting = initGetData === Initialize.START || initGetData === Initialize.LOADING;
    React.useEffect(() => {
        if (open && itemsIdSelected.length !== 0) {
            dispatchModal({ type: ACT_MODAL.INIT_MODAL_START });
        }
    }, [itemsIdSelected.length, open]);
    React.useEffect(() => {
        if (!open && initModal !== Initialize.START) {
            dispatchModal({ type: ACT_MODAL.RESET });
        }
    }, [initModal, open]);
    React.useEffect(() => {
        (async () => {
            if (initModal === Initialize.START) {
                try {
                    dispatchModal({ type: ACT_MODAL.INIT_MODAL_LOADING });
                    const doc = await getJSPref([JsContext]);
                    dispatchModal({
                        type: ACT_MODAL.INIT_MODAL_SUCC,
                        catalogs,
                        columns,
                        slcCtlg,
                        slcLang,
                        selections: doc[JsContext],
                    });
                }
                catch (err) {
                    const error = await getErrorMsg(err);
                    dispatchModal({ type: ACT_MODAL.INIT_MODAL_FAIL, error });
                }
            }
        })();
    }, [catalogs, columns, initModal, slcCtlg, slcLang]);
    React.useEffect(() => {
        (async () => {
            if (initGetData === Initialize.START) {
                try {
                    dispatchModal({ type: ACT_MODAL.INIT_GETDATA_LOADING });
                    const itemsId = items.map((a) => a.id);
                    const idsToGet = itemsIdSelected.filter((string) => !itemsId.includes(string));
                    const itemsToAdd = await getProducts({
                        ids: idsToGet.slice(0, ITEMS_TOGET),
                        columns,
                        categories,
                        source: [KEY_READY_ATTRS],
                    });
                    dispatchModal({
                        type: ACT_MODAL.INIT_GETDATA_SUCC,
                        items: itemsToAdd,
                        itemsIdSelected,
                    });
                }
                catch (err) {
                    dispatchModal({ type: ACT_MODAL.INIT_GETDATA_FAIL, error });
                }
            }
        })();
    }, [categories, columns, error, initGetData, items, itemsIdSelected]);
    React.useEffect(() => {
        (async () => {
            if (!!selectionDraft && selectionsInit === Initialize.START) {
                dispatchModal({ type: ACT_MODAL.SELECTION_INIT_LOADING });
                try {
                    if (selectionsActn === JsonstoreAction.UPDATE) {
                        await putJSPref(JsContext, selectionDraft.id, selectionDraft.payload);
                    }
                    else if (selectionsActn === JsonstoreAction.DELETE) {
                        await delJSPref(JsContext, selectionDraft.id);
                    }
                    else {
                        throw "action unknow";
                    }
                }
                catch (err) {
                    console.log("--ERROR", err);
                }
                dispatchModal({ type: ACT_MODAL.SELECTION_INIT_STOPPED });
            }
        })();
    }, [selectionDraft, selectionsActn, selectionsInit]);
    React.useEffect(() => {
        (async () => {
            if (initConfirm === Initialize.START) {
                try {
                    dispatchModal({ type: ACT_MODAL.INIT_CONFIRM_LOADING });
                    await multiReady(readySets, MultiReadyTarget.Attributes, MultiReadyOp.Set);
                    if (saveSelections) {
                        try {
                            await addJSPref(JsContext, {
                                label: "Export of " +
                                    moment(new Date().getTime()).format(`${DATE_FORMAT} HH:mm`),
                                slcAttrs: attrsSelected,
                                slcCtlgs: ctlgsSelected,
                                slcLangs: langsSelected,
                            });
                        }
                        catch {
                            console.log("error save jsonstore");
                        }
                    }
                    setSnackbar(Severity.SUCC, "Ready Status will be applied");
                    onClose();
                }
                catch (err) {
                    const errMsg = await getErrorMsg(err);
                    setSnackbar(Severity.WARN, errMsg);
                    dispatchModal({ type: ACT_MODAL.INIT_CONFIRM_STOPPED });
                }
            }
        })();
    }, [
        attrsSelected,
        ctlgsSelected,
        initConfirm,
        langsSelected,
        onClose,
        readySets,
        saveSelections,
        setSnackbar,
    ]);
    if (open && Initialize.SUCC && !itWork) {
        return (React.createElement(Modal, { loading: loading, open: true, onClose: onClose, title: "Can\u2019t apply Ready status", content: React.createElement(React.Fragment, null,
                React.createElement(Text, { children: "This action can\u2019t be done because there aren\u2019t attributes to set Ready" }),
                React.createElement(Text, { children: "or the selected entities aren't associated to any catalog" })), actions: React.createElement(React.Fragment, null,
                React.createElement("div", { style: { flex: 1 } }),
                React.createElement(Btn, { color: getTheme().colors.msgSucc, variant: "bold", label: "OK, I UNDERSTAND", onClick: onClose })) }));
    }
    if (open &&
        Initialize.SUCC &&
        new Set(items.map((a) => a[KEY_ENTITY_TYPE])).size > 1) {
        return (React.createElement(ModalErrorMultiLevel, { title: title, loading: loading, onClose: onClose }));
    }
    if (!!error) {
        return (React.createElement(Modal, { loading: loading, open: open, onClose: onClose, title: "Unable to process the request", content: React.createElement(Text, { children: error }), actions: React.createElement(React.Fragment, null,
                React.createElement("div", { style: { flex: 1 } }),
                React.createElement(Btn, { color: getTheme().colors.msgSucc, variant: "bold", label: "OK, I UNDERSTAND", onClick: onClose })) }));
    }
    if (getting) {
        return (React.createElement(Modal, { open: open, onClose: onClose, content: React.createElement(React.Fragment, null,
                React.createElement("div", { style: { flex: 1 } }),
                React.createElement(Toolbar, null,
                    React.createElement(CircularProgress, { size: 40 }),
                    React.createElement("div", { style: { flex: 1 } }),
                    React.createElement(Text, { weight: "bolder", style: { margin: "0 25px" }, children: "Preparing your selection..." }),
                    React.createElement("div", { style: { flex: 1 } }),
                    React.createElement(Text, { weight: "bolder", style: { color: getTheme().colors.theme1 }, children: `${items.length}/${itemsIdSelected.length}` })),
                React.createElement("div", { style: { flex: 1 } })) }));
    }
    return (React.createElement(Modal, { loading: loading, open: open, onClose: onClose, title: title, titleHelp: "Please select which attributes you want to set Ready", content: React.createElement("div", { className: classes.panel },
            React.createElement("div", { className: classes.content },
                catalogs.length <= 1 ? null : (React.createElement(ListCtlgs, { dispatchModal: dispatchModal, items: items, slcCtlg: ctlgsSelected[0], readOnly: spinner })),
                catalogs.length <= 1 && langs.length <= 1 ? null : (React.createElement(ListLangs, { dispatchModal: dispatchModal, slcCtlg: ctlgsSelected[0], slcLangs: langsSelected, readOnly: spinner })),
                !ctlgsSelected.length ? null : (React.createElement(ListAttrs, { dispatchModal: dispatchModal, items: items, slcCtlgs: ctlgsSelected, slcLangs: langsSelected, slcAttrs: attrsSelected, input: attrsInputted, readOnly: spinner })),
                !!ctlgsSelected.length ? null : (React.createElement("div", { style: {
                        position: "relative",
                        width: WIDTH_LANGS + WIDTH_ATTRS,
                    } },
                    React.createElement(Placeholder, { icon: "announcement", label: "Please select a catalog" })))),
            React.createElement(Divider, null),
            React.createElement(Toolbar, { className: classes.saveSlcToolbar },
                React.createElement(ListItem, { className: classes.saveSlcButton, id: "btnSaveSelected", label: "Save the current selection in your preference", selected: saveSelections, selectType: SelectType.CHECK, onClick: onSelectionsSave, disabled: checkboxDisabled || spinner, help: checkboxDisabled
                        ? "You have reached the maximum number of saved selections\nPlease delete a selection in order to create a new one"
                        : undefined }),
                React.createElement("div", { style: { flex: 1 } }),
                selectionsInit === Initialize.LOADING ? (React.createElement(CircularProgress, { size: 20, style: { marginRight: 6 } })) : !selections.length ? null : (React.createElement(Btn, { tooltip: "Choose from saved selection", icon: "article", onClick: onSelectionsOpen, disabled: spinner }))),
            React.createElement(ListSavedSelections, { dispatchModal: dispatchModal, open: openSelections, loading: initModal === Initialize.LOADING ||
                    selectionsInit === Initialize.LOADING, items: items, selections: selections })), actions: openSelections ? (React.createElement("div", { style: { flex: 1 } })) : (React.createElement(React.Fragment, null,
            spinner ? (React.createElement(TextLoading, null)) : (React.createElement(LinkSummaryReady, { readySets: readySets, slcAttrs: attrsSelected })),
            React.createElement("div", { style: { flex: 1 } }),
            React.createElement(Btn, { variant: "bold", label: "CANCEL", onClick: onClose }),
            React.createElement(Btn, { variant: "bold", label: "CONFIRM", disabled: !ctlgsSelected.length ||
                    !langsSelected.length ||
                    !attrsSelected.length, color: getTheme().colors.msgSucc, onClick: onConfirm }))) }));
};
export default ModalAttributesReady;
