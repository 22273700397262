const getMediaReady = (ready, media, view, catalogIdsSelected) => {
    const mediaIds = media.filter((m) => m.view == view).map((m) => m.fileId);
    // ottengo l'id del ready del media nella view passata
    // dovrebbe essere solo uno, se sono di più è un errore e per prevenirlo facciamo il "pop"
    const mediaReadyId = Array.from(new Set(ready
        .filter((r) => mediaIds.includes(r.contentId) &&
        catalogIdsSelected.includes(r.catalog))
        .map((r) => r.contentId))).pop();
    return media.filter((m) => m.fileId == mediaReadyId).pop();
};
export default getMediaReady;
