import * as React from "react";
import MainContext from "./MainContext";
import SnackbarMessage from "./SnackbarMessage";
import SnackbarOffline from "./SnackbarOffline";
import MainToolbar from "./MainToolbar";
import { createUseStyles } from "react-jss";
import { ERROR_SAVE_PREF, USER_PREF_DEFAULT } from "../constants";
import { openWebSocket, registerWebSocketCallback, unregisterWebSocketCallback, } from "./webSocket";
import webSocketCallbacks from "./websocketCallbacks";
import { ACTION_MAIN, reducer, reducerInitState } from "./reducer";
import getSections from "./getSections";
import WindowDownloads from "./WindowDownloads";
import UploadsSC from "./Uploads/UploadsSC";
import UploadsDA from "./Uploads/UploadsDA";
import UploadsML from "./Uploads/UploadsML";
import { Initialize, NotificationType, Severity, } from "@warda/library-ui/interfaces";
import { getUserDatas } from "../api/fetchesApiUsers";
import { useHistory } from "react-router-dom";
import { logOut, saveUserData, requestTokenWithRefreshToken, } from "../api/fetchCookieJwt";
import Cookies from "js-cookie";
import { addJSPref, putJSPref } from "../api/fetchesApiJsonStore";
import { JsonstoreContext, PREF_KEY, PRODUCT_ID } from "../constantsKey";
import localstorage from "@warda/library-ui/localstorage";
import PlaceholderMain from "./PlaceholderMain";
import { SnackbarProvider, useSnackbar } from "notistack";
import MainSection from "./MainSection";
import { getTheme, setTheme } from "@warda/library-ui/theme";
import { AUTHORIZATION_TOKEN, REFRESH_TOKEN, TENANT_ID, } from "../constantsLocalStorage";
import { THEMES } from "../colors";
import updatePreferences from "../utils/updatePreferences";
import { createNotification } from "../api/fetchesApi";
const setPageTitle = (title, { tenantId, tenants }) => {
    const tenant = tenants.find((t) => t.tenantId === tenantId);
    document.title = `${title} ${tenant.label}`;
};
const useStyles = createUseStyles({
    main: {
        backgroundColor: getTheme().colors.background,
        position: "relative",
        flex: 1,
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: "stretch",
    },
    mainContent: {
        position: "relative",
        flex: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        minHeight: 0, // fix sidepanels height
    },
});
const Main = ({ title, viewId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const classes = useStyles({});
    const [state, dispatch] = React.useReducer(reducer, reducerInitState);
    const { propagationStatus, refreshToken, initialize, preferencesInit, preferencesJsId, preferences, downloads, uploadsSC, uploadsML, uploadsDA, permissions, userProfile, users, m2ms, } = state;
    const urlWebSocket = `${process.env.WEBSOCKET_URL}/socket/${PRODUCT_ID}`;
    React.useEffect(() => {
        (async () => {
            if (initialize === Initialize.START) {
                dispatch({ type: ACTION_MAIN.INITIALIZE_LOADING });
                try {
                    const { users, m2ms, userProfile, preferences, preferencesJsId, } = await getUserDatas();
                    let myPrefs = preferences;
                    let myPrefsJsId = preferencesJsId;
                    if (!myPrefsJsId) {
                        // new user without preferences
                        const mngItemPref = await addJSPref(JsonstoreContext.userPreferences, USER_PREF_DEFAULT);
                        myPrefs = mngItemPref.payload;
                        myPrefsJsId = mngItemPref.id;
                    }
                    myPrefs = updatePreferences(myPrefs); // clean unused prefs
                    // setto il tema salvato nelle preferenze
                    const myPrefsThemeId = myPrefs[PREF_KEY.themeId];
                    const currentThemeId = getTheme().id;
                    const myPrefsTheme = THEMES.find((t) => t.id === myPrefsThemeId);
                    if (myPrefsThemeId !== currentThemeId && !!myPrefsTheme) {
                        setTheme(myPrefsTheme);
                        location.reload();
                    }
                    // aggiorno il tema se quello nel local storage è vecchio
                    const myPrefsThemeVersion = myPrefsTheme.version;
                    const currentThemeVersion = getTheme().version;
                    if (myPrefsThemeVersion !== currentThemeVersion) {
                        setTheme(myPrefsTheme);
                        location.reload();
                    }
                    const permissions = userProfile.permissions
                        .find((p) => {
                        return p.applicationId === PRODUCT_ID;
                    })
                        .permissions.map((p) => {
                        return { id: p, label: "" };
                    });
                    const sections = getSections(permissions);
                    if (!users.length) {
                        throw "no users found";
                    }
                    const mySectionIds = sections
                        .filter((s) => !s.disabled)
                        .map((s) => s.id);
                    if (!mySectionIds.length) {
                        throw "no sections to access";
                    }
                    const viewIdExist = new Set(mySectionIds).has(viewId);
                    if (!viewIdExist) {
                        history.push(`/${mySectionIds[0]}/`);
                    }
                    setPageTitle(title, userProfile);
                    saveUserData(userProfile);
                    dispatch({
                        type: ACTION_MAIN.INITIALIZE_SUCC,
                        users,
                        m2ms,
                        permissions,
                        userProfile,
                        preferences: myPrefs,
                        preferencesJsId: myPrefsJsId,
                    });
                }
                catch (err) {
                    console.log("[error] initialize", { err });
                    logOut(history);
                }
            }
        })();
    }, [history, initialize, title, viewId]);
    // Avvio websocket
    React.useEffect(() => {
        openWebSocket(urlWebSocket);
    }, [urlWebSocket]);
    // add websocket listener
    React.useEffect(() => {
        webSocketCallbacks.forEach((x) => {
            registerWebSocketCallback({
                id: x.id,
                callback: x.callback,
                dispatch,
            });
        });
        return () => {
            webSocketCallbacks.forEach((x) => {
                unregisterWebSocketCallback(x.id);
            });
        };
    }, []);
    // refreshToken
    React.useEffect(() => {
        if (refreshToken) {
            (async () => {
                try {
                    const refToken = await requestTokenWithRefreshToken();
                    if (refToken.status >= 400)
                        throw refToken;
                    dispatch({ type: ACTION_MAIN.REFRESH_TOKEN, v: false });
                    console.log("[authorizationToken] obtained and saved");
                    openWebSocket(urlWebSocket);
                }
                catch (err) {
                    console.log("[authorizationToken] error");
                    logOut(history);
                }
            })();
        }
    }, [urlWebSocket, history, refreshToken]);
    // authorizationToken
    React.useEffect(() => {
        const interval = setInterval(() => {
            if (!refreshToken) {
                if (userProfile &&
                    localstorage.getItem(TENANT_ID) &&
                    userProfile.tenantId !== localstorage.getItem(TENANT_ID)) {
                    // -- caso switchTenant
                    // nel setInterval metto 2000ms perché meno il tema potrebbe non applicarsi
                    // correttamente se nel passaggio al nuovo tenant c'è un tema differente
                    location.reload();
                }
                if (Cookies.get(REFRESH_TOKEN) && !Cookies.get(AUTHORIZATION_TOKEN)) {
                    // -- caso token di autorizzazione scaduto
                    console.log("[authorizationToken] expired");
                    dispatch({ type: ACTION_MAIN.REFRESH_TOKEN, v: true });
                }
            }
        }, 2000);
        return () => clearInterval(interval);
    }, [refreshToken, userProfile]);
    // save preferences
    React.useEffect(() => {
        (async () => {
            if (userProfile && preferencesInit === Initialize.START) {
                dispatch({ type: ACTION_MAIN.PREFERENCES_INIT_LOADING });
                try {
                    await putJSPref(JsonstoreContext.userPreferences, preferencesJsId, preferences);
                    const themeId = preferences[PREF_KEY.themeId];
                    if (getTheme().id !== themeId) {
                        await createNotification({
                            toUsers: [userProfile.userId],
                            type: NotificationType.THEME,
                            payload: { themeId },
                        });
                        setTheme(THEMES.find((t) => t.id === themeId));
                    }
                }
                catch {
                    enqueueSnackbar(ERROR_SAVE_PREF, { variant: Severity.FAIL });
                }
                dispatch({ type: ACTION_MAIN.PREFERENCES_INIT_STOPPED });
            }
        })();
    }, [
        enqueueSnackbar,
        preferences,
        preferencesInit,
        preferencesJsId,
        userProfile,
    ]);
    if (initialize !== Initialize.SUCC) {
        return React.createElement(PlaceholderMain, null);
    }
    return (React.createElement(SnackbarProvider, { preventDuplicate: true, maxSnack: 3, Components: {
            default: SnackbarMessage,
            success: SnackbarMessage,
            error: SnackbarMessage,
            warning: SnackbarMessage,
            info: SnackbarMessage,
        } },
        React.createElement(MainContext, { dispatchMain: dispatch, permissions: permissions, users: users, m2ms: m2ms, userData: userProfile, userPref: preferences },
            React.createElement("div", { className: classes.main },
                React.createElement(MainToolbar, { title: title, sectionId: viewId, propagationStatus: propagationStatus }),
                React.createElement("div", { className: classes.mainContent },
                    React.createElement(MainSection, { viewId: viewId }))),
            React.createElement(UploadsSC, { uploads: uploadsSC }),
            React.createElement(UploadsDA, { uploads: uploadsDA }),
            React.createElement(UploadsML, { uploads: uploadsML }),
            React.createElement(WindowDownloads, { downloads: downloads }),
            React.createElement(SnackbarOffline, null))));
};
export default Main;
