import * as React from "react";
import Toolbar from "@warda/library-ui/core/Toolbar";
import Text from "@warda/library-ui/core/Text";
import List from "@warda/library-ui/core/List";
import Divider from "@warda/library-ui/core/Divider";
import { ACT_MODAL } from "./reducer";
import { CtxColumns } from "../contexts";
import ListItem from "@warda/library-ui/core/ListItem";
import { getColumnsCanBeReady, WIDTH_ATTRS } from "./utils";
import FieldSearch from "@warda/library-ui/core/FieldSearch";
import useStyles from "./useStylesList";
import LinkSummaryAttrs from "./LinkSummaryAttrs";
import Placeholder from "@warda/library-ui/core/Placeholder";
import { SelectType } from "@warda/library-ui/core/Checkbox";
const getListitems = (items, columns, slcCtlgs, slcLangs, slcAttrs) => {
    const selected = new Set(slcAttrs);
    return getColumnsCanBeReady({
        columns,
        items,
        slcCtlgs,
        slcLangs,
    })
        .map((c) => {
        return {
            id: c.id,
            label: c.label,
            selected: selected.has(c.id),
        };
    })
        .sort((a, b) => {
        if (a.label > b.label)
            return 1;
        if (a.label < b.label)
            return -1;
        return 0;
    });
};
const ListAttrs = ({ dispatchModal, items, slcCtlgs, slcLangs, slcAttrs, input, readOnly, }) => {
    const classes = useStyles({});
    const columns = React.useContext(CtxColumns);
    const selected = new Set(slcAttrs);
    const listitems = getListitems(items, columns, slcCtlgs, slcLangs, slcAttrs);
    const listitemsSearched = listitems.filter((c) => {
        const labelF = c.label.toLocaleLowerCase();
        const inputF = input.toLocaleLowerCase();
        return labelF.includes(inputF);
    });
    const selectedAll = !listitems.some((a) => !selected.has(a.id));
    const onClick = React.useCallback((e, id) => {
        dispatchModal({ type: ACT_MODAL.ATTRS_SELECTED, id });
    }, [dispatchModal]);
    const onSelectAll = React.useCallback(() => {
        dispatchModal({
            type: ACT_MODAL.ATTRS_SLC_ALL,
            ids: listitems.map((a) => a.id),
        });
    }, [dispatchModal, listitems]);
    const onChangeInput = React.useCallback((value) => {
        dispatchModal({ type: ACT_MODAL.ATTRS_INPUTTED, value });
    }, [dispatchModal]);
    return (React.createElement("div", { className: classes.panel, style: { width: WIDTH_ATTRS, borderRight: 0 } },
        React.createElement(Toolbar, { className: classes.toolbar },
            React.createElement(Text, { weight: "bolder", children: "Select Attributes" }),
            React.createElement("div", { style: { flex: 1 } }),
            React.createElement(LinkSummaryAttrs, { items: items, slcAttrs: slcAttrs, slcCtlgs: slcCtlgs, slcLangs: slcLangs })),
        React.createElement(List, { style: { paddingTop: 0 } },
            React.createElement(ListItem, { className: classes.listitem, id: "all", label: !listitems.length ? " " : "Select All", selected: selectedAll, selectType: SelectType.CHECK, onClick: onSelectAll, disabled: !listitems.length || readOnly })),
        React.createElement(Divider, { className: classes.divider }),
        !listitems.length ? (React.createElement("div", { style: { position: "relative", flex: 1 } },
            React.createElement(Placeholder, { icon: "announcement", label: "No attributes to set Ready" }))) : (React.createElement(React.Fragment, null,
            React.createElement(FieldSearch, { className: classes.input, value: input, onChange: onChangeInput }),
            React.createElement(List, { className: classes.list }, !listitemsSearched.length ? (React.createElement(Placeholder, { icon: "search", label: "No attribute found" })) : (listitemsSearched.map(({ id, label, selected }) => (React.createElement(ListItem, { className: classes.listitem, key: id, id: id, label: label, selected: selected, selectType: SelectType.CHECK, onClick: onClick, input: input, disabled: readOnly })))))))));
};
export default ListAttrs;
