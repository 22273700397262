import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import { createUseStyles } from "react-jss";
import Btn from "@warda/library-ui/core/Btn";
import BtnCheckbox from "@warda/library-ui/core/BtnCheckbox";
import Badge from "@warda/library-ui/core/Badge";
import classnames from "classnames";
import TextLoading from "@warda/library-ui/core/TextLoading";
import { MultiReadyOp } from "../ToolbarHeader/BtnReadys/BtnReadys";
var LABEL;
(function (LABEL) {
    LABEL["review"] = "REVIEW";
    LABEL["ready"] = "READY";
    LABEL["checked"] = "CHECKED";
})(LABEL || (LABEL = {}));
const labelColorMap = {
    [LABEL.review]: getTheme().colors.msgWarn,
    [LABEL.ready]: getTheme().colors.msgSucc,
    [LABEL.checked]: getTheme().colors.theme1,
};
const getLabel = (type, count) => {
    const item = (c) => `item${c > 1 ? "s" : ""}`;
    const color = labelColorMap[type];
    switch (type) {
        case LABEL.review:
            return {
                color,
                label: (React.createElement(React.Fragment, null,
                    React.createElement("span", { children: `Skip ${count} ${item(count)} in ` }),
                    React.createElement("span", { style: { color }, children: "REVIEW" }))),
            };
        case LABEL.ready:
            return {
                color,
                label: (React.createElement(React.Fragment, null,
                    React.createElement("span", { children: `Skip ${count} ` }),
                    React.createElement("span", { style: { color }, children: "READY" }),
                    React.createElement("span", { children: ` ${item(count)}` }))),
            };
        case LABEL.checked:
            return {
                color,
                label: (React.createElement(React.Fragment, null,
                    React.createElement("span", { children: `Only ${count} ` }),
                    React.createElement("span", { style: { color }, children: "CHECKED" }),
                    React.createElement("span", { children: ` ${item(count)}` }))),
            };
        default:
            return {
                color: getTheme().colors.disable,
                label: "NONE",
            };
    }
};
const useStyles = createUseStyles({
    flex1: {
        flex: 1,
        "min-width": 100,
    },
    footer: {
        padding: 0,
    },
    badge: {
        transition: "all 250ms",
        transform: "scale(0)",
        position: "absolute",
        "z-index": 1,
        top: "-10px",
        right: "-10px",
    },
    badgeShow: {
        transform: "scale(1)",
    },
});
const Footer = ({ loading, multiReadyOp, btnItemsCheckedOnToggle, btnItemsCheckedSelected, btnItemsCheckedCount, btnItemsReviewOnToggle, btnItemsReviewSelected, btnItemsReviewCount, btnItemsReadyOnToggle, btnItemsReadySelected, btnItemsReadyCount, itemsToApplyCount, confirmDisabled, onConfirm, onCancel, }) => {
    const classes = useStyles({});
    return (React.createElement(React.Fragment, null,
        loading ? (React.createElement(TextLoading, null)) : multiReadyOp === MultiReadyOp.Set ? (React.createElement(React.Fragment, null,
            React.createElement(BtnCheckbox, { ...getLabel(LABEL.review, btnItemsReviewCount), selected: btnItemsReviewSelected, onClick: btnItemsReviewOnToggle, tooltip: "Check to skip item in review", style: { marginRight: 5 } }),
            React.createElement(BtnCheckbox, { ...getLabel(LABEL.ready, btnItemsReadyCount), selected: btnItemsReadySelected, onClick: btnItemsReadyOnToggle, tooltip: "Check to skip item ready", style: { marginRight: 5 } }),
            React.createElement(BtnCheckbox, { ...getLabel(LABEL.checked, btnItemsCheckedCount), selected: btnItemsCheckedSelected, onClick: btnItemsCheckedOnToggle, tooltip: "Check to apply only to item checked", style: { marginRight: 5 } }))) : null,
        React.createElement("div", { className: classes.flex1 }),
        React.createElement(Btn, { variant: "bold", label: "CANCEL", onClick: onCancel }),
        React.createElement("div", { style: { position: "relative" } },
            React.createElement(Badge, { className: classnames({
                    [classes.badge]: true,
                    [classes.badgeShow]: !!itemsToApplyCount,
                }), color: getTheme().colors.msgSucc, label: String(itemsToApplyCount) }),
            React.createElement(Btn, { variant: "bold", color: getTheme().colors.msgSucc, label: "CONFIRM", onClick: onConfirm, disabled: confirmDisabled }))));
};
export default Footer;
