import { ViewType } from "../../../../interfaces";
import { MultiReadyTarget } from "../../ToolbarHeader/BtnReadys/BtnReadys";
const multyReadyTargetToViewType = (multiReadyTarget) => {
    switch (multiReadyTarget) {
        case MultiReadyTarget.Media:
            return ViewType.MEDIA;
        case MultiReadyTarget.Documents:
            return ViewType.DOCUMENT;
        default:
            throw "Unmanaged multiReadyTarget";
    }
};
export default multyReadyTargetToViewType;
