import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import Popover from "@warda/library-ui/core/Popover";
import Text from "@warda/library-ui/core/Text";
import Divider from "@warda/library-ui/core/Divider";
import List from "@warda/library-ui/core/List";
import { CtxCtlgs, CtxColumns } from "../contexts";
import ListItem from "@warda/library-ui/core/ListItem";
import classnames from "classnames";
import Btn from "@warda/library-ui/core/Btn";
import useStyles from "./useStylesLink";
import { getAttrsApplicable } from "./utils";
const defaultOriginAnchor = {
    vertical: "bottom",
    horizontal: "right",
};
const defaultOriginTransf = {
    vertical: "top",
    horizontal: "right",
};
const getListitems = (columns, catalog, slcAttrs, slcLangs) => {
    const langs = slcLangs.map((l) => l.toLocaleUpperCase()).join(", ");
    const listitemsGlobal = [];
    const listitemsMuCtlg = [];
    slcAttrs
        .map((attrId) => {
        return columns.find((c) => c.id === attrId);
    })
        .filter((c) => {
        return !!c;
    })
        .sort((a, b) => {
        if (a.label > b.label)
            return 1;
        if (a.label < b.label)
            return -1;
        return 0;
    })
        .forEach((c) => {
        const { id, label, multiCatalog, multiLanguage } = c;
        const listitem = {
            id,
            title: false,
            label,
            subLabel: multiLanguage ? langs : undefined,
        };
        if (multiCatalog) {
            listitemsMuCtlg.push(listitem);
        }
        else {
            listitemsGlobal.push(listitem);
        }
    });
    if (!!listitemsGlobal.length) {
        const listitem = {
            id: "_global",
            title: true,
            label: "Global",
        };
        listitemsGlobal.splice(0, 0, listitem);
    }
    if (!!listitemsMuCtlg.length) {
        const listitem = {
            id: "_ctlg",
            title: true,
            label: catalog.displayName,
        };
        listitemsMuCtlg.splice(0, 0, listitem);
    }
    return listitemsGlobal.concat(listitemsMuCtlg);
};
const LinkSummaryAttrs = ({ title = "Current Selection", label = "selected", items, slcAttrs, slcCtlgs, slcLangs, menuOriginAnchor = defaultOriginAnchor, menuOriginTransf = defaultOriginTransf, }) => {
    const classes = useStyles({});
    const columns = React.useContext(CtxColumns);
    const catalogs = React.useContext(CtxCtlgs);
    const ctlg = catalogs.find((c) => c.id === slcCtlgs[0]);
    const menuRef = React.useRef(null);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const onMenuOpen = React.useCallback(() => {
        setMenuOpen(true);
    }, []);
    const onMenuClose = React.useCallback(() => {
        setMenuOpen(false);
    }, []);
    const canAttrs = getAttrsApplicable({
        columns,
        items,
        slcAttrs,
        slcCtlgs,
        slcLangs,
    });
    const color = !canAttrs.length
        ? getTheme().colors.msgFail
        : getTheme().colors.theme1;
    if (!slcAttrs.length)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(Btn, { cmpRef: menuRef, selected: menuOpen, variant: "light", style: { margin: 0 }, color: color, labelStyle: { color }, label: (canAttrs.length === slcAttrs.length
                ? slcAttrs.length
                : `${canAttrs.length} / ${slcAttrs.length}`) +
                " " +
                label, onClick: onMenuOpen }),
        React.createElement(Popover, { open: menuOpen, anchorEl: menuRef.current, onClose: onMenuClose, originAnchor: menuOriginAnchor, originTransf: menuOriginTransf },
            React.createElement(Text, { size: 3, weight: "bolder", className: classes.title, children: title }),
            React.createElement(Divider, { className: classes.divider }),
            React.createElement(List, { className: classes.list }, getListitems(columns, ctlg, slcAttrs, slcLangs).map(({ id, title, label, subLabel }) => {
                const error = !title && !new Set(canAttrs).has(id);
                return (React.createElement(ListItem, { key: id, id: id, className: classnames({
                        [classes.listitem]: true,
                        [classes.listitemTitle]: title,
                    }), icon: error ? "warning" : undefined, iconStyle: { color: getTheme().colors.msgWarn }, iconTooltip: "This attribute cannot be associated to selected entities", label: label, subLabel: subLabel, subLabelStyle: { color: getTheme().colors.theme1 } }));
            })))));
};
export default LinkSummaryAttrs;
