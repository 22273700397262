import isEmpty from "lodash-es/isEmpty";
import { MultiReadyOp } from "../../ToolbarHeader/BtnReadys/BtnReadys";
/**
 * Function that return a new array of catalogs configurations disabled/enabled.
 * a catalog will be enabled if there aren't selected view in ready for that catalog.
 */
const checkCatalogsDisabled = (multiReadyOp, catalogs, views) => {
    if (multiReadyOp === MultiReadyOp.Unset) {
        return catalogs;
    }
    return catalogs.map((c) => {
        const { id, selected, viewsReady } = c;
        // if there aren't ready for this catalog -> nothing changed return catalog
        if (!selected || isEmpty(viewsReady))
            return c;
        const viewsReadySet = new Set(viewsReady);
        // if all view in Ready of this catalog are deselected -> return catalog enabled
        const viewsReadySelected = views.filter((v) => {
            return id === v.catalog && v.selected && viewsReadySet.has(v.view);
        });
        return {
            ...c,
            disabled: !isEmpty(viewsReadySelected),
        };
    });
};
export default checkCatalogsDisabled;
