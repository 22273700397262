import { createUseStyles } from "react-jss";
import * as React from "react";
const useStyles = createUseStyles({
    listDivider: {
        width: 1,
        background: "#ddd",
        margin: "0 10px",
    },
});
const ListDivider = () => {
    const classes = useStyles({});
    return React.createElement("div", { className: classes.listDivider });
};
export default ListDivider;
