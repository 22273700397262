import { PRODUCT_ID } from "../constantsKey";
import serializeObject from "../utils/serializeObject";
import { BASE_URL } from "../constants";
import { Service } from "@warda/library-ui/interfaces";
// NOTE taccone se è true abilita la vecchia api prima dello sviluppo di Gabri
// W3IM-1029
// SEECOMM-7511
const taccone = true;
var SEECOMMERCE_UPLOAD_TYPE;
(function (SEECOMMERCE_UPLOAD_TYPE) {
    SEECOMMERCE_UPLOAD_TYPE["POST"] = "post";
    SEECOMMERCE_UPLOAD_TYPE["SHOOTING"] = "shooting";
    SEECOMMERCE_UPLOAD_TYPE["XLSX"] = "xlsx";
    SEECOMMERCE_UPLOAD_TYPE["XLSXPRO"] = "xlsxpro";
    SEECOMMERCE_UPLOAD_TYPE["VIDEO"] = "video";
    SEECOMMERCE_UPLOAD_TYPE["DOCUMENT"] = "document";
})(SEECOMMERCE_UPLOAD_TYPE || (SEECOMMERCE_UPLOAD_TYPE = {}));
const apiUrls = {
    newUploadDA: {
        url: () => `${BASE_URL}/${Service.DA}/upload/new`,
        method: "POST",
    },
    newUploadSC: {
        url: (type) => `${BASE_URL}/${Service.SC}/products/upload${type ? `/${type}` : ""}/new`,
        method: "POST",
    },
    profilePictureUpload: {
        url: () => `${BASE_URL}/${Service.USERS}/user/profile/picture/upload`,
        method: "POST",
    },
    profilePictureConfirm: {
        url: (fileId) => `${BASE_URL}/${Service.USERS}/user/profile/picture/confirm/${fileId}`,
        method: "PUT",
    },
    newUploadConfirm: {
        url: (fileId, service, type) => `${BASE_URL}/${service === Service.SC ? service + "/products" : service}/upload/confirm${type ? `/${type}` : ""}/${fileId}`,
        method: "PUT",
    },
    deleteSeecommerceMedia: {
        url: (fileId, type) => `${BASE_URL}/${Service.SC}/products/media-content${type ? `/${type}` : ""}/${fileId}`,
        method: "DELETE",
    },
    msearch: {
        url: () => `${BASE_URL}/${Service.SC}/msearch`,
        method: "POST",
    },
    search: {
        url: (index, service) => `${BASE_URL}/${service}/search/${index}`,
        method: "POST",
    },
    postMultiReady: {
        url: (type) => `${BASE_URL}/${Service.SC}/products/${type}/multiReady`,
        method: "POST",
    },
    postReady: {
        url: (type) => `${BASE_URL}/${Service.SC}/products/${type}/ready`,
        method: "POST",
    },
    deleteReady: {
        url: (type) => `${BASE_URL}/${Service.SC}/products/${type}/ready`,
        method: "DELETE",
    },
    postViewCheck: {
        url: () => `${BASE_URL}/${Service.SC}/products/viewCheck`,
        method: "POST",
    },
    postViews: {
        url: () => `${BASE_URL}/${Service.SC}/products/views`,
        method: "POST",
    },
    postProduction: {
        url: () => `${BASE_URL}/${Service.SC}/products/postProduction`,
        method: "POST",
    },
    postExportData: {
        url: () => `${BASE_URL}/${Service.SC}/export`,
        method: "POST",
    },
    downloadMassiveDA: {
        url: () => `${BASE_URL}/${Service.DA}/massive-download/scheduleWithRename`,
        method: "POST",
    },
    downloadMassiveSC: {
        url: () => `${BASE_URL}/${Service.SC}/products/download-massive`,
        method: "POST",
    },
    downloadMediaDA: {
        url: (fileId, rendId) => {
            const base = `${BASE_URL}/${Service.DA}/media-content/${fileId}`;
            if (!rendId)
                return base;
            return base + `/${rendId}`;
        },
        method: "GET",
    },
    downloadMediaSC: {
        url: (fileId, params) => {
            let base = `${BASE_URL}/${Service.SC}/media/download-single/${fileId}`;
            if (!params)
                return base;
            const obj = { ...params };
            Object.keys(obj).forEach((k) => {
                if (obj[k] === undefined || obj[k] === null)
                    delete obj[k];
            });
            if (taccone) {
                base = `${BASE_URL}/mcr/media-content/${fileId}`;
                if (!!obj.rendition) {
                    base = base + "/" + obj.rendition;
                    delete obj.rendition;
                }
            }
            return base + "?" + serializeObject(obj);
        },
        method: "GET",
    },
    zoomMediaDA: {
        url: (fileId) => `${BASE_URL}/${Service.DA}/media-content/${fileId}/zoom-tiles/tiles.dzi?redirect=false`,
        method: "GET",
    },
    zoomMediaSC: {
        url: (fileId) => {
            if (taccone) {
                return `${BASE_URL}/mcr/media-content/${fileId}/zoom-tiles/tiles.dzi?redirect=false`;
            }
            return `${BASE_URL}/${Service.SC}/media/zoom-tiles/${fileId}/tiles.dzi?redirect=false`;
        },
        method: "GET",
    },
    editProduct: {
        url: () => `${BASE_URL}/${Service.SC}/products`,
        method: "PUT",
    },
    editMultiProduct: {
        url: () => `${BASE_URL}/${Service.SC}/products/multiProduct`,
        method: "PUT",
    },
    assignment: {
        url: () => `${BASE_URL}/${Service.SC}/products/assignment`,
        method: "POST",
    },
    multiAssignment: {
        url: () => `${BASE_URL}/${Service.SC}/products/multiAssignment`,
        method: "POST",
    },
    distributions: {
        url: () => `${BASE_URL}/${Service.SC}/products/distributions`,
        method: "POST",
    },
    multiViewDataMedia: {
        url: () => `${BASE_URL}/${Service.SC}/products/multiViewData`,
        method: "POST",
    },
    multiViewDataDocuments: {
        url: () => `${BASE_URL}/${Service.SC}/products/document/multiViewData`,
        method: "POST",
    },
    multiResetViews: {
        url: () => `${BASE_URL}/${Service.SC}/products/multiResetViews`,
        method: "POST",
    },
    resetAttribute: {
        url: () => `${BASE_URL}/${Service.SC}/products/resetAttribute`,
        method: "POST",
    },
    putFolder: {
        url: () => `${BASE_URL}/${Service.DA}/folder`,
        method: "PUT",
    },
    putFile: {
        url: () => `${BASE_URL}/${Service.DA}/file`,
        method: "PUT",
    },
    moveFiles: {
        url: () => `${BASE_URL}/${Service.DA}/files`,
        method: "POST",
    },
    deleteFile: {
        url: () => `${BASE_URL}/${Service.DA}/files`,
        method: "DELETE",
    },
    postFolder: {
        url: () => `${BASE_URL}/${Service.DA}/folder`,
        method: "POST",
    },
    postFile: {
        url: () => `${BASE_URL}/${Service.DA}/file`,
        method: "POST",
    },
    canPublicFile: {
        url: (service, fileId) => `${BASE_URL}/${service}/publish/publishable/${fileId}`,
        method: "GET",
    },
    publiccdn: {
        url: () => `${BASE_URL}/${Service.DA}/publiccdn`,
        method: "POST",
    },
    share: {
        url: () => `${BASE_URL}/${Service.DA}/share`,
        method: "POST",
    },
    searchDiz: {
        url: () => `${BASE_URL}/${Service.DA}/search/diz`,
        method: "POST",
    },
    createItemTag: {
        url: () => `${BASE_URL}/${Service.DA}/itemtag`,
        method: "PUT",
    },
    deleteItemTag: {
        url: () => `${BASE_URL}/${Service.DA}/itemtag`,
        method: "DELETE",
    },
    deleteTag: {
        url: () => `${BASE_URL}/${Service.DA}/tag`,
        method: "DELETE",
    },
    createNotification: {
        url: () => `${BASE_URL}/notifier/notifications/${Service.SC}`,
        method: "POST",
    },
    descendants: {
        url: () => `${BASE_URL}/${Service.DA}/descendants`,
        method: "POST",
    },
    removeNotification: {
        url: (id) => `${BASE_URL}/notifier/notifications/${Service.SC}/${id}`,
        method: "DELETE",
    },
    notificationMarkRead: {
        url: (id, mark) => `${BASE_URL}/notifier/notifications/${Service.SC}/${id}/read?mark=${mark}`,
        method: "PUT",
    },
    notificationMarkAllRead: {
        url: (mark) => `${BASE_URL}/notifier/notifications/${Service.SC}/read?mark=${mark}`,
        method: "PUT",
    },
    notificationMarkView: {
        url: (id, mark) => `${BASE_URL}/notifier/notifications/${Service.SC}/${id}/view?mark=${mark}`,
        method: "PUT",
    },
    notificationMarkAllView: {
        url: (mark) => `${BASE_URL}/notifier/notifications/${Service.SC}/view?mark=${mark}`,
        method: "PUT",
    },
    deleteNotifications: {
        url: () => `${BASE_URL}/notifier/notifications/${Service.SC}`,
        method: "DELETE",
    },
    getNotifications: {
        url: (size, from, filter) => `${BASE_URL}/notifier/notifications/${Service.SC}?size=${size}&from=${from}${!filter ? "" : `&filter=${filter}`}`,
        method: "GET",
    },
    getNotificationsCount: {
        url: (filter) => `${BASE_URL}/notifier/notifications/${Service.SC}/count?${!filter ? "" : `&filter=${filter}`}`,
        method: "GET",
    },
    getNotificationById: {
        url: (id) => `${BASE_URL}/notifier/notifications/${Service.SC}/${id}`,
        method: "GET",
    },
    exportProductEvents: {
        url: () => `${BASE_URL}/${Service.SC}/products/events`,
        method: "POST",
    },
    exportXlsx: {
        url: () => `${BASE_URL}/${Service.SC}/export/v2`,
        method: "POST",
    },
    postEasyCatalog: {
        url: () => `${BASE_URL}/${Service.SC}/export/easyCatalog`,
        method: "POST",
    },
    getFiles: {
        url: (p) => `${BASE_URL}/${Service.DA}/files?${serializeObject(p)}`,
        method: "GET",
    },
    getFileDetail: {
        url: (ids) => `${BASE_URL}/${Service.DA}/filesdetail?${serializeObject({
            ids: JSON.stringify(ids),
        })}`,
        method: "GET",
    },
    privateshares: {
        url: () => `${BASE_URL}/${Service.DA}/privateshares`,
        method: "PUT",
    },
    getTags: {
        url: (name, size) => `${BASE_URL}/${Service.DA}/tags?${serializeObject({
            name,
            size,
        })}`,
        method: "GET",
    },
    getSeecommerceProduct: {
        url: (product) => `${BASE_URL}/${Service.SC}/products?product=${product}`,
        method: "GET",
    },
    putUserProfile: {
        url: () => `${BASE_URL}/${Service.USERS}/user/profile`,
        method: "PUT",
    },
    getUsers: {
        url: () => `${BASE_URL}/${Service.USERS}/user?app=${PRODUCT_ID}`,
        method: "GET",
    },
    getAdminPermissions: {
        url: () => `${BASE_URL}/${Service.USERS}/admin/permissions`,
        method: "GET",
    },
    getPermissions: {
        url: () => `${BASE_URL}/${Service.USERS}/permissions`,
        method: "GET",
    },
    patchPermission: {
        url: (id) => `${BASE_URL}/${Service.USERS}/admin/permissions/${id}`,
        method: "PATCH",
    },
    getAdminRoles: {
        url: () => `${BASE_URL}/${Service.USERS}/admin/roles?app=${PRODUCT_ID}`,
        method: "GET",
    },
    getRoles: {
        url: () => `${BASE_URL}/${Service.USERS}/roles?app=${PRODUCT_ID}`,
        method: "GET",
    },
    putAdminRoles: {
        url: () => `${BASE_URL}/${Service.USERS}/admin/tenant/roles`,
        method: "PUT",
    },
    putRoles: {
        url: () => `${BASE_URL}/${Service.USERS}/tenant/roles`,
        method: "PUT",
    },
    postAdminRoles: {
        url: () => `${BASE_URL}/${Service.USERS}/admin/tenant/roles`,
        method: "POST",
    },
    postRoles: {
        url: () => `${BASE_URL}/${Service.USERS}/tenant/roles`,
        method: "POST",
    },
    deleteRoles: {
        url: () => `${BASE_URL}/${Service.USERS}/tenant/roles`,
        method: "DELETE",
    },
    getM2msData: {
        url: () => `${BASE_URL}/${Service.USERS}/m2ms`,
        method: "GET",
    },
    getAdminM2msData: {
        url: () => `${BASE_URL}/${Service.USERS}/admin/m2ms`,
        method: "GET",
    },
    getUsersData: {
        url: () => `${BASE_URL}/${Service.USERS}/users?app=${PRODUCT_ID}`,
        method: "GET",
    },
    getAdminUsersData: {
        url: () => `${BASE_URL}/${Service.USERS}/admin/users?app=${PRODUCT_ID}`,
        method: "GET",
    },
    getTenant: {
        url: () => `${BASE_URL}/${Service.USERS}/tenant?app=${PRODUCT_ID}`,
        method: "GET",
    },
    getUserProfile: {
        url: () => `${BASE_URL}/${Service.USERS}/user/profile?app=${PRODUCT_ID}`,
        method: "GET",
    },
    resendUserEmail: {
        url: () => `${BASE_URL}/${Service.USERS}/resendInvitation`,
        method: "POST",
    },
    updateUserEmail: {
        url: (userId) => `${BASE_URL}/${Service.USERS}/user/${userId}/email`,
        method: "PUT",
    },
    putTenantStatus: {
        url: (userId) => `${BASE_URL}/${Service.USERS}/user/${userId}/tenant/statusChange`,
        method: "PUT",
    },
    putCopyright: {
        url: () => `${BASE_URL}/${Service.DA}/copyright`,
        method: "PUT",
    },
    createUser: {
        url: () => `${BASE_URL}/${Service.USERS}/user`,
        method: "POST",
    },
    createUserAdmin: {
        url: () => `${BASE_URL}/${Service.USERS}/admin/user`,
        method: "POST",
    },
    modifyUser: {
        url: (userId) => `${BASE_URL}/${Service.USERS}/user/${userId}`,
        method: "PUT",
    },
    modifyUserAdmin: {
        url: (userId) => `${BASE_URL}/${Service.USERS}/admin/user/${userId}`,
        method: "PUT",
    },
    enableTenantUser: {
        url: () => `${BASE_URL}/${Service.USERS}/user/tenant`,
        method: "POST",
    },
    getProduct: {
        url: (id) => `${BASE_URL}/${Service.SC}/products?${serializeObject({
            product: id,
        })}`,
        method: "GET",
    },
    getJobs: {
        url: () => `${BASE_URL}/${Service.NOTIFIER}/jobs/${Service.SC}?size=250&from=0`,
        method: "GET",
    },
    getJobId: {
        url: (id) => `${BASE_URL}/${Service.NOTIFIER}/jobs/${Service.SC}/${id}`,
        method: "GET",
    },
    deleteJobs: {
        url: () => `${BASE_URL}/${Service.NOTIFIER}/jobs/${Service.SC}`,
        method: "DELETE",
    },
    deleteJobId: {
        url: (id) => `${BASE_URL}/${Service.NOTIFIER}/jobs/${Service.SC}/${id}`,
        method: "DELETE",
    },
    getTenantConfig: {
        url: (tenantId, aggregate) => `${BASE_URL}/${Service.SC}/config/${tenantId}/${aggregate}`,
        method: "GET",
    },
    putTenantConfig: {
        url: () => `${BASE_URL}/${Service.SC}/config`,
        method: "PUT",
    },
    getAttributeDicts: {
        url: () => `${BASE_URL}/${Service.SC}/config/dictionary`,
        method: "POST",
    },
    getMcrDocumentId: {
        url: (fileId) => `${BASE_URL}/${Service.SC}/media/download-document/${fileId}/documentId`,
        method: "GET",
    },
    getMcrDocumentToken: {
        url: (docId) => `${BASE_URL}/${Service.SC}/media/download-document/${docId}/access-token`,
        method: "GET",
    },
    completenessCount: {
        url: () => `${BASE_URL}/${Service.SC}/completeness-count`,
        method: "POST",
    },
    getLogoTenant: {
        url: (tenantId) => `${BASE_URL}/mcr/logo/${tenantId}`,
        method: "GET",
    },
    getEntityBussinessIds: {
        url: (entityId) => `${BASE_URL}/${Service.SC}/products/businessIds?${serializeObject({
            product: entityId,
        })}`,
        method: "GET",
    },
    revokeToken: {
        url: () => `${BASE_URL}/${Service.USERS}/admin/user/revokeToken`,
        method: "POST",
    },
};
export default apiUrls;
