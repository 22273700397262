import { getUser } from "@warda/library-ui/utils/getUser";
import { BASE_URL } from "../constants";
import { Service } from "@warda/library-ui/interfaces";
import { fetchCookieJwtWithRefreshToken } from "./fetchCookieJwt";
const apiUrls = {
    getAnnotationsList: {
        url: () => `${BASE_URL}/${Service.SC}/annotations`,
        method: "POST",
    },
    getAnnotationsById: {
        url: () => `${BASE_URL}/${Service.SC}/annotation/id`,
        method: "POST",
    },
    createAnnotation: {
        url: () => `${BASE_URL}/${Service.SC}/annotation`,
        method: "POST",
    },
    deleteAnnotation: {
        url: () => `${BASE_URL}/${Service.SC}/annotation/id`,
        method: "DELETE",
    },
    updateAnnotation: {
        url: () => `${BASE_URL}/${Service.SC}/annotation/id`,
        method: "PUT",
    },
    resolveAnnotation: {
        url: () => `${BASE_URL}/${Service.SC}/annotation/resolve`,
        method: "POST",
    },
};
const formatAnnotation = (users, ann) => {
    const { id, lastmodified, createdon, createdby, resolved } = ann;
    const { color, geometry, mentionsIds, value, } = JSON.parse(ann.data);
    return {
        data: {
            id,
            color,
            resolved,
            dateUpdated: new Date(lastmodified).getTime(),
            dateCreated: new Date(createdon).getTime(),
            value,
        },
        geometry,
        user: getUser(createdby, users, []),
        mentions: mentionsIds.map((i) => getUser(i, users, [])),
    };
};
export const getAnnotationsList = async (entities, error) => {
    try {
        if (error)
            throw "NO_LOAD";
        const { url, method } = apiUrls.getAnnotationsList;
        const res = await fetchCookieJwtWithRefreshToken({
            url: url(),
            method: method,
            jsonBody: {
                entities,
            },
        });
        return res;
    }
    catch (err) {
        return {
            resolved: [],
            unresolved: [],
        };
    }
};
export const getAnnotationsById = async ({ users, entityId, fileId, }) => {
    try {
        const { url, method } = apiUrls.getAnnotationsById;
        const res = await fetchCookieJwtWithRefreshToken({
            url: url(),
            method: method,
            jsonBody: {
                entityId,
                fileId,
            },
        });
        return res.map((ann) => formatAnnotation(users, ann));
    }
    catch (err) {
        console.log("getAnnotationsById", err);
        return [];
    }
};
export const createAnnotation = async ({ users, entityId, view, fileId, data, }) => {
    try {
        const { url, method } = apiUrls.createAnnotation;
        const ann = await fetchCookieJwtWithRefreshToken({
            url: url(),
            method: method,
            jsonBody: {
                entityId,
                view,
                fileId,
                data,
            },
        });
        return formatAnnotation(users, ann);
    }
    catch (err) {
        console.log("createAnnotation", err);
        return undefined;
    }
};
export const deleteAnnotation = async ({ users, id, }) => {
    try {
        const { url, method } = apiUrls.deleteAnnotation;
        const ann = await fetchCookieJwtWithRefreshToken({
            url: url(),
            method: method,
            jsonBody: {
                id,
            },
        });
        return formatAnnotation(users, ann);
    }
    catch (err) {
        console.log("deleteAnnotation", err);
        return undefined;
    }
};
export const updateAnnotation = async ({ users, id, data, }) => {
    try {
        const { url, method } = apiUrls.updateAnnotation;
        const ann = await fetchCookieJwtWithRefreshToken({
            url: url(),
            method: method,
            jsonBody: {
                id,
                data,
            },
        });
        return formatAnnotation(users, ann);
    }
    catch (err) {
        console.log("updateAnnotation", err);
        return undefined;
    }
};
export const resolveAnnotation = async ({ users, id, resolved, }) => {
    try {
        const { url, method } = apiUrls.resolveAnnotation;
        const ann = await fetchCookieJwtWithRefreshToken({
            url: url(),
            method: method,
            jsonBody: {
                id,
                resolved,
            },
        });
        return formatAnnotation(users, ann);
    }
    catch (err) {
        console.log("resolveAnnotation", err);
        return undefined;
    }
};
