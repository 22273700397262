import { KEY_MEDIA, KEY_READY_MEDIA, KEY_VIEW_DATA, } from "../../../../constantsKey";
import { MediaType, ContentType, ViewStatus, ViewCheck, } from "../../../../interfaces";
import getMediaLastUploaded from "../../getMediaLastUploaded";
import getMediaLastReady from "../../getMediaLastReady";
import getViewDetail from "../../getViewDetail";
import { MultiReadyOp, MultiReadyTarget, } from "../../ToolbarHeader/BtnReadys/BtnReadys";
import multyReadyTargetToViewType from "./multiReadyTargetToViewType";
import getMediaReady from "./getMediaReady";
const getCatalogIdsToReady = (catalog, catalogIdsSelected) => {
    return (catalog || []).reduce((acc, catId) => {
        const isSelected = catalogIdsSelected.find((id) => id === catId);
        if (isSelected)
            acc.push(catId);
        return acc;
    }, []);
};
const getProductSelectedViews = (productViews, viewType, catalogIdsSelected, slcViews) => {
    return productViews.filter((v) => {
        return (v.viewDataType === viewType &&
            (slcViews !== null && slcViews !== void 0 ? slcViews : []).some((s) => s.selected &&
                s.view === v.viewName &&
                catalogIdsSelected.includes(s.catalog)));
    });
};
// ritirna vero se matcha conun MediaType valido per la messa a ready
const checkMediaForReady = (media, multiReadyTarget) => {
    const { mediaType } = media;
    switch (multiReadyTarget) {
        case MultiReadyTarget.Documents:
            // se il media è un documento non ha MediaType
            return !mediaType;
        case MultiReadyTarget.Media:
            return new Set([MediaType.IMAGE_PP, MediaType.VIDEO]).has(mediaType);
        default:
            throw `Check to implement for ${multiReadyTarget}`;
    }
};
/**
 * This function return the count of elements and an array of "readySet" to send to BE
 * (now both for Set and Unset)
 *
 * ReadySet is a object composed from:
 * * product's id
 * * product's version
 * * array of elements composed from:
 * * * element's id (media's fileId)
 * * * element's idType (ContentType.media)
 * * * element's array of catalog ids to apply Ready Status
 *
 * to obtain the array of readySet, we must cycle to items and, for every viewstatus,
 * check if this view is selected and get the viewstatus' catalogs and the media avaiable to apply Ready Status.
 *
 * The media, in this case, is our "element"
 * The media to apply Ready Status must be:
 * * last media uploaded in that viewstatus
 * * mediaType POST_PRODUCED or VIDEO
 */
const getReadySets = ({ multiReadyOp, multiReadyTarget, items, slcCtlgs, slcViews, skipItemsReady = false, skipItemsReview = false, onlyItemsChecked = false, }) => {
    const viewType = multyReadyTargetToViewType(multiReadyTarget);
    let countItemsToApply = 0;
    let countItemsTotal = 0;
    let countItemsInReview = 0;
    let countItemsInReady = 0;
    let countItemsInChecked = 0;
    // get all catalog ids selected with checkboxes
    const catalogIdsSelected = slcCtlgs.reduce((acc, cat) => {
        if (cat.selected)
            acc.push(cat.id);
        return acc;
    }, []);
    const readySets = items.reduce((acc, item) => {
        const itemReady = (item[KEY_READY_MEDIA] || []);
        const itemMedia = (item[KEY_MEDIA] || []);
        const productViews = (item[KEY_VIEW_DATA] || []);
        // find elementsReady for every viewstatus
        const elementsReady = getProductSelectedViews(productViews, viewType, catalogIdsSelected, slcViews)
            .map((v) => {
            const viewData = v;
            const viewDetail = getViewDetail(item, v.viewName);
            return { viewData, viewDetail };
        })
            .filter(({ viewDetail }) => {
            const { catalog } = viewDetail;
            const catalogIdsToReady = getCatalogIdsToReady(catalog, catalogIdsSelected);
            // check se c'è almeno un catalogo su cui mettere il ready
            return catalogIdsToReady.length > 0;
        })
            .map(({ viewData, viewDetail }) => {
            const media = multiReadyOp === MultiReadyOp.Set
                ? getMediaLastUploaded(itemMedia, viewData.viewName)
                : getMediaReady(itemReady, itemMedia, viewData.viewName, catalogIdsSelected);
            return { viewData, viewDetail, media };
        })
            .filter(({ media }) => {
            // check se esiste un media caricato su quella vista e si assicura che tale media sia
            return !!media && checkMediaForReady(media, multiReadyTarget);
        })
            .reduce((acc, { viewDetail, media }) => {
            const { viewName, catalog, status, check } = viewDetail;
            const catalogIdsToReady = multiReadyOp === MultiReadyOp.Set
                ? getCatalogIdsToReady(catalog, catalogIdsSelected)
                : itemReady
                    .filter((r) => r.contentId == media.fileId)
                    .map((r) => r.catalog);
            countItemsTotal += 1;
            if (multiReadyOp === MultiReadyOp.Set) {
                const viewHasReady = !!getMediaLastReady(itemReady, itemMedia, viewName);
                const viewInReview = status === ViewStatus.REVIEW;
                const viewInChecked = check === ViewCheck.CHECK;
                if (viewInChecked)
                    countItemsInChecked += 1;
                if (viewInReview)
                    countItemsInReview += 1;
                if (viewHasReady)
                    countItemsInReady += 1;
                if (onlyItemsChecked && !viewInChecked)
                    return acc;
                if (skipItemsReview && viewInReview)
                    return acc;
                if (skipItemsReady && viewHasReady)
                    return acc;
            }
            countItemsToApply += 1;
            const readySet = {
                id: media.fileId,
                idType: ContentType.MEDIA,
                catalogs: catalogIdsToReady,
            };
            acc.push(readySet);
            return acc;
        }, []);
        // return this readySet only if there is at least one element to set Ready Status
        if (!!elementsReady.length) {
            acc.push({
                entityId: item.id,
                version: items.length > 1 ? -1 : item.version,
                elementsReady,
            });
        }
        return acc;
    }, []);
    return {
        readySets,
        countItemsTotal,
        countItemsToApply,
        countItemsInReview,
        countItemsInReady,
        countItemsInChecked,
    };
};
export default getReadySets;
