import * as React from "react";
import Text from "@warda/library-ui/core/Text";
import Btn from "@warda/library-ui/core/Btn";
import { getTheme } from "@warda/library-ui/theme";
import useStyles from "../component/useStyles";
const ContentSuccess = ({ onConfirm, confirmLabel }) => {
    const classes = useStyles({});
    return (React.createElement(React.Fragment, null,
        React.createElement(Text, { className: classes.title, children: "\uD83D\uDD11" }),
        React.createElement(Text, { size: 2, weight: "bolder", style: { textAlign: "center", marginBottom: 40 }, children: "Your Warda password has been set!" }),
        React.createElement(Btn, { style: { margin: "auto" }, variant: "bold", color: getTheme().colors.theme1, label: confirmLabel, onClick: onConfirm })));
};
export default ContentSuccess;
