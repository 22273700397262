import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import Btn from "@warda/library-ui/core/Btn";
import Modal from "@warda/library-ui/core/Modal";
import Text from "@warda/library-ui/core/Text";
const ModalErrorMultiLevel = ({ title, loading, onClose, }) => (React.createElement(Modal, { loading: loading, open: true, onClose: onClose, title: title, content: React.createElement(React.Fragment, null,
        React.createElement(Text, { children: "Unable work with selected products." }),
        React.createElement(Text, { children: "Please select items of the same level." })), actions: React.createElement(React.Fragment, null,
        React.createElement("div", { style: { flex: 1 } }),
        React.createElement(Btn, { variant: "bold", color: getTheme().colors.msgSucc, label: "OK, I UNDERSTAND", onClick: onClose })) }));
export default ModalErrorMultiLevel;
