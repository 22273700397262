import { KEY_VIEW_DATA } from "../../../../constantsKey";
import { sortByKey } from "@warda/library-ui/utils/sort";
import multiReadyTarget2ViewType from "./multiReadyTargetToViewType";
/**
 * Function that return views configurations to create checkboxes.
 * It cycles on items' viewsStatus
 */
const getMultiReadyViews = (items, multiReadyTarget) => {
    const viewType = multiReadyTarget2ViewType(multiReadyTarget);
    const viewsCatalogs = Object.values(items.reduce((acc, item) => {
        const viewsData = item[KEY_VIEW_DATA] || [];
        viewsData
            .filter((v) => {
            return v.viewDataType === viewType;
        })
            .sort((a, b) => {
            if (a.viewName > b.viewName)
                return 1;
            if (a.viewName < b.viewName)
                return -1;
            return 0;
        })
            .forEach((vs) => {
            const { catalog, viewName, category } = vs;
            const key = `${viewName}-${category}`;
            if (acc[key]) {
                acc[key].catalog = Array.from(new Set([...acc[key].catalog, ...catalog]));
            }
            else {
                acc[key] = { viewName, category, catalog };
            }
        });
        return acc;
    }, {}));
    const views = viewsCatalogs.reduce((acc, vs) => {
        vs.catalog.forEach((catalog) => {
            acc.push({
                id: `${catalog}_${vs.viewName}`,
                view: vs.viewName,
                catalog,
                category: vs.category,
                selected: true,
            });
        });
        return acc;
    }, []);
    sortByKey(views, "catalog");
    return views;
};
export default getMultiReadyViews;
