import { Initialize } from "@warda/library-ui/interfaces";
import { getAttrsApplicable } from "./utils";
import { JsonstoreAction } from "../../../constantsKey";
export const MAX_SELECTIONS = 50;
const updateArray = (oldArray, id) => {
    const array = Array.from(oldArray);
    const indexToRemove = array.findIndex((i) => i === id);
    if (indexToRemove === -1) {
        array.push(id);
    }
    else {
        array.splice(indexToRemove, 1);
    }
    return array;
};
const selectAllArray = (oldArray, ids) => {
    const array = Array.from(oldArray);
    const toAdd = ids.some((i) => !new Set(array).has(i));
    if (toAdd) {
        ids.forEach((id) => {
            const arrSet = new Set(array);
            if (!arrSet.has(id))
                array.push(id);
        });
    }
    else {
        ids.forEach((id) => {
            const indexToRemove = array.findIndex((i) => i === id);
            array.splice(indexToRemove, 1);
        });
    }
    return array;
};
const updateAttrsOnChangeLangs = ({ columns, slcAttrs, slcLangs, }) => {
    if (slcLangs.length === 0) {
        // rimuovo gli attributi multilang
        return slcAttrs.filter((attrId) => {
            const col = columns.find((c) => c.id === attrId);
            return !col || !col.multiLanguage;
        });
    }
    return slcAttrs;
};
export var ACT_MODAL;
(function (ACT_MODAL) {
    ACT_MODAL["RESET"] = "RESET";
    ACT_MODAL["INIT_MODAL_START"] = "INIT_MODAL_START";
    ACT_MODAL["INIT_MODAL_LOADING"] = "INIT_MODAL_LOADING";
    ACT_MODAL["INIT_MODAL_SUCC"] = "INIT_MODAL_SUCC";
    ACT_MODAL["INIT_MODAL_FAIL"] = "INIT_MODAL_FAIL";
    ACT_MODAL["INIT_CONFIRM_STARTED"] = "INIT_CONFIRM_STARTED";
    ACT_MODAL["INIT_CONFIRM_LOADING"] = "INIT_CONFIRM_LOADING";
    ACT_MODAL["INIT_CONFIRM_STOPPED"] = "INIT_CONFIRM_STOPPED";
    ACT_MODAL["INIT_GETDATA_LOADING"] = "INIT_GETDATA_LOADING";
    ACT_MODAL["INIT_GETDATA_SUCC"] = "INIT_GETDATA_SUCC";
    ACT_MODAL["INIT_GETDATA_FAIL"] = "INIT_GETDATA_FAIL";
    ACT_MODAL["CTLGS_SELECTED"] = "CTLGS_SELECTED";
    ACT_MODAL["LANGS_SELECTED"] = "LANGS_SELECTED";
    ACT_MODAL["LANGS_SLC_ALL"] = "LANGS_SLC_ALL";
    ACT_MODAL["ATTRS_SELECTED"] = "ATTRS_SELECTED";
    ACT_MODAL["ATTRS_INPUTTED"] = "ATTRS_INPUTTED";
    ACT_MODAL["ATTRS_SLC_ALL"] = "ATTRS_SLC_ALL";
    ACT_MODAL["SELECTION_INIT_LOADING"] = "SELECTION_INIT_LOADING";
    ACT_MODAL["SELECTION_INIT_STOPPED"] = "SELECTION_INIT_STOPPED";
    ACT_MODAL["SELECTION_DELETE"] = "SELECTION_DELETE";
    ACT_MODAL["SELECTION_RENAME"] = "SELECTION_RENAME";
    ACT_MODAL["SELECTIONS_LIST_SLCT"] = "SELECTIONS_LIST_SLCT";
    ACT_MODAL["SELECTIONS_LIST_OPEN"] = "SELECTIONS_LIST_OPEN";
    ACT_MODAL["SELECTIONS_LIST_SAVE"] = "SELECTIONS_LIST_SAVE";
})(ACT_MODAL || (ACT_MODAL = {}));
export const reducerInitState = {
    initModal: Initialize.NONE,
    initConfirm: Initialize.NONE,
    initGetData: Initialize.NONE,
    items: [],
    error: "",
    columns: [],
    ctlgsSelected: [],
    langsSelected: [],
    attrsSelected: [],
    attrsInputted: "",
    saveSelections: true,
    openSelections: false,
    selections: [],
    selectionsActn: JsonstoreAction.GET,
    selectionsInit: Initialize.NONE,
    selectionDraft: undefined,
};
const reducer = (state, action) => {
    var _a;
    const newState = { ...state };
    switch (action.type) {
        case ACT_MODAL.RESET:
            return reducerInitState;
        case ACT_MODAL.INIT_MODAL_START:
            newState.initModal = Initialize.START;
            return newState;
        case ACT_MODAL.INIT_MODAL_LOADING:
            newState.initModal = Initialize.LOADING;
            return newState;
        case ACT_MODAL.INIT_MODAL_FAIL:
            newState.initModal = Initialize.FAIL;
            newState.error = action.error;
            return newState;
        case ACT_MODAL.INIT_MODAL_SUCC: {
            const columns = action.columns;
            const catalogs = action.catalogs;
            const slcCtlg = action.slcCtlg;
            const slcLang = action.slcLang;
            const selectedCtlg = ((_a = catalogs.find((c) => c.id === slcCtlg)) === null || _a === void 0 ? void 0 : _a.id) || "";
            const selectedLang = slcLang || "";
            const selections = action.selections;
            newState.initModal = Initialize.SUCC;
            newState.initGetData = Initialize.START;
            newState.columns = columns;
            newState.selections = selections;
            newState.selectionsInit = reducerInitState.selectionsInit;
            newState.selectionsActn = reducerInitState.selectionsActn;
            newState.selectionDraft = reducerInitState.selectionDraft;
            newState.saveSelections = newState.selections.length <= MAX_SELECTIONS;
            if (!!selectedCtlg)
                newState.ctlgsSelected = [selectedCtlg];
            if (!!selectedLang)
                newState.langsSelected = [selectedLang];
            if (catalogs.length === 1 && newState.ctlgsSelected.length === 0) {
                const ctlg = catalogs[0];
                newState.ctlgsSelected = [ctlg.id];
                newState.langsSelected = ctlg.languages;
                newState.attrsSelected = reducerInitState.attrsSelected;
            }
            return newState;
        }
        case ACT_MODAL.INIT_CONFIRM_STARTED:
            newState.initConfirm = Initialize.START;
            return newState;
        case ACT_MODAL.INIT_CONFIRM_LOADING:
            newState.initConfirm = Initialize.LOADING;
            return newState;
        case ACT_MODAL.INIT_CONFIRM_STOPPED:
            newState.initConfirm = Initialize.NONE;
            return newState;
        case ACT_MODAL.INIT_GETDATA_LOADING:
            newState.initGetData = Initialize.LOADING;
            return newState;
        case ACT_MODAL.INIT_GETDATA_SUCC: {
            const itemsIdSelected = action.itemsIdSelected;
            const items = action.items;
            const newItems = Array.from(newState.items);
            items.forEach((a) => newItems.push(a));
            newState.items = newItems;
            newState.initGetData =
                newItems.length === itemsIdSelected.length
                    ? Initialize.SUCC
                    : Initialize.START;
            return newState;
        }
        case ACT_MODAL.INIT_GETDATA_FAIL:
            newState.initGetData = Initialize.FAIL;
            newState.error = action.error || newState.error;
            return newState;
        case ACT_MODAL.ATTRS_INPUTTED:
            newState.attrsInputted = action.value;
            return newState;
        case ACT_MODAL.ATTRS_SLC_ALL:
            newState.attrsSelected = selectAllArray(newState.attrsSelected, action.ids);
            return newState;
        case ACT_MODAL.ATTRS_SELECTED:
            newState.attrsSelected = updateArray(newState.attrsSelected, action.id);
            return newState;
        case ACT_MODAL.LANGS_SELECTED:
            newState.langsSelected = updateArray(newState.langsSelected, action.id);
            newState.attrsSelected = updateAttrsOnChangeLangs({
                columns: newState.columns,
                slcAttrs: newState.attrsSelected,
                slcLangs: newState.langsSelected,
            });
            return newState;
        case ACT_MODAL.LANGS_SLC_ALL:
            newState.langsSelected = selectAllArray(newState.langsSelected, action.ids);
            newState.attrsSelected = updateAttrsOnChangeLangs({
                columns: newState.columns,
                slcAttrs: newState.attrsSelected,
                slcLangs: newState.langsSelected,
            });
            return newState;
        case ACT_MODAL.CTLGS_SELECTED:
            newState.ctlgsSelected = [action.id];
            newState.langsSelected = reducerInitState.langsSelected;
            newState.attrsSelected = reducerInitState.attrsSelected;
            return newState;
        case ACT_MODAL.SELECTIONS_LIST_OPEN:
            newState.openSelections = !newState.openSelections;
            return newState;
        case ACT_MODAL.SELECTIONS_LIST_SAVE:
            newState.saveSelections = !newState.saveSelections;
            return newState;
        case ACT_MODAL.SELECTION_DELETE: {
            const { id } = action;
            const newSlc = Array.from(newState.selections);
            const index = newSlc.findIndex((s) => s.id === id);
            const newItem = { ...newSlc[index] };
            newSlc.splice(index, 1);
            newState.selectionsInit = Initialize.START;
            newState.selectionsActn = JsonstoreAction.DELETE;
            newState.selectionDraft = newItem;
            newState.selections = newSlc;
            return newState;
        }
        case ACT_MODAL.SELECTION_RENAME: {
            const { id, label } = action;
            const newSlc = Array.from(newState.selections);
            const index = newSlc.findIndex((s) => s.id === id);
            const item = newSlc[index];
            const newItem = {
                ...item,
                payload: {
                    ...item.payload,
                    label,
                },
            };
            newSlc.splice(index, 1, newItem);
            newState.selectionsInit = Initialize.START;
            newState.selectionsActn = JsonstoreAction.UPDATE;
            newState.selectionDraft = newItem;
            newState.selections = newSlc;
            return newState;
        }
        case ACT_MODAL.SELECTIONS_LIST_SLCT: {
            const { columns, items } = newState;
            const selectionId = action.id;
            const selection = newState.selections.find((s) => s.id === selectionId);
            const { slcCtlgs, slcLangs, slcAttrs } = selection.payload;
            newState.ctlgsSelected = slcCtlgs;
            newState.langsSelected = slcLangs;
            newState.attrsSelected = getAttrsApplicable({
                columns,
                items,
                slcAttrs,
                slcCtlgs,
                slcLangs,
            });
            newState.saveSelections = false;
            newState.openSelections = false;
            newState.attrsInputted = reducerInitState.attrsInputted;
            return newState;
        }
        case ACT_MODAL.SELECTION_INIT_LOADING:
            newState.selectionsInit = Initialize.LOADING;
            return newState;
        case ACT_MODAL.SELECTION_INIT_STOPPED:
            newState.selectionsInit = reducerInitState.selectionsInit;
            newState.selectionsActn = reducerInitState.selectionsActn;
            newState.selectionDraft = reducerInitState.selectionDraft;
            return newState;
        default:
            return state;
    }
};
export default reducer;
