import { KEY_MEDIA, KEY_READY_MEDIA } from "../../../../constantsKey";
// Function that return an array of view in which that catalog is in ready
const getViewsReady = ({ ctlgId, items, views, viewType, }) => {
    const viewsReady = items.reduce((acc, item) => {
        const itemReady = (item[KEY_READY_MEDIA] || []);
        const itemMedia = (item[KEY_MEDIA] || []);
        itemReady
            .filter((r) => {
            return r.catalog === ctlgId;
        })
            .filter((r) => {
            return !!itemMedia.find((m) => m.fileId === r.contentId);
        })
            .forEach((r) => {
            const media = itemMedia.find((m) => m.fileId === r.contentId);
            const view = views.find((v) => v.viewName === media.view);
            if (!!view && view.viewType === viewType) {
                acc.push(media.view);
            }
        });
        return acc;
    }, []);
    // tolgo i doppioni e lo ordino
    return Array.from(new Set(viewsReady)).sort();
};
export default getViewsReady;
