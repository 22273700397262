import * as React from "react";
import { createUseStyles } from "react-jss";
import Toolbar from "@warda/library-ui/core/Toolbar";
import WindowNotifJob from "./WindowNotifJob";
import WindowNotifDrc from "./WindowNotifDrc";
import BtnWindowProfile from "./WindowProfile";
import BtnWindowSections from "./WindowSections";
import BtnWindowChangelog from "./WindowChangelog";
import BtnWindowTenants from "./WindowTenants";
import BtnWindowTheme from "./WindowTheme";
import Propagation from "./Propagation";
import { getTheme } from "@warda/library-ui/theme";
const useStyles = createUseStyles({
    mainToolbar: {
        padding: 0,
        alignItems: "stretch",
    },
    mainActions: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        position: "relative",
        padding: "0 15px",
        borderBottom: `1px solid ${getTheme().colors.grayBorder}`,
    },
});
const MainToolbar = ({ title, sectionId, propagationStatus }) => {
    const classes = useStyles({});
    return (React.createElement(Toolbar, { className: classes.mainToolbar },
        React.createElement(BtnWindowTenants, null),
        React.createElement("div", { className: classes.mainActions },
            React.createElement(BtnWindowSections, { title: title, sectionId: sectionId }),
            React.createElement("div", { style: { flex: 1 } }),
            React.createElement(Propagation, { status: propagationStatus }),
            React.createElement(WindowNotifDrc, null),
            React.createElement(WindowNotifJob, null),
            React.createElement(BtnWindowTheme, null),
            React.createElement(BtnWindowChangelog, { title: title }),
            React.createElement(BtnWindowProfile, null))));
};
export default MainToolbar;
