const getReadyLast = (ready) => {
    const lastOne = ready.reduce((acc, r) => {
        const instant = acc ? acc.instant : 0;
        if (instant < r.instant)
            return r;
        return acc;
    }, null);
    return lastOne;
};
const getMediaLastReady = (ready, media, view) => {
    const lastReadyArray = media.reduce((acc, m) => {
        if (m.view !== view)
            return acc;
        const thisFileIdReady = ready.filter((r) => r.contentId === m.fileId);
        const lastReady = getReadyLast(thisFileIdReady);
        if (lastReady)
            acc.push(lastReady);
        return acc;
    }, []);
    const lastReady = getReadyLast(lastReadyArray);
    if (!lastReady)
        return null;
    // media messo in "ready" più recente
    return media.find((m) => m.fileId === lastReady.contentId);
};
export default getMediaLastReady;
