import { mergeProductDatas, getDataDictionaries } from "./useSearchEs";
import { KEY_ENTITY_ID, KEY_MEDIA, KEY_PUBLC_MEDIA, KEY_PUBLC_ATTRS, KEY_READY_MEDIA, KEY_READY_ATTRS, } from "../../constantsKey";
import { getAnnotationsList } from "../../api/fetchesApiAnnotations";
const getProductData = async ({ datas, columns, categories, getAnnotations = false, }) => {
    const id = datas.tabular.entityId;
    const businessIds = datas.businessIds;
    const tabular = datas.tabular;
    const tabularroot = datas.tabularroot;
    const attributeSets = datas.attributeSets;
    const media = {
        id,
        [KEY_ENTITY_ID]: id,
        [KEY_MEDIA]: datas.media,
    };
    const ready = {
        id,
        [KEY_ENTITY_ID]: id,
        [KEY_READY_MEDIA]: datas[KEY_READY_MEDIA] || [],
        [KEY_READY_ATTRS]: datas[KEY_READY_ATTRS] || [],
    };
    const publications = {
        id,
        [KEY_ENTITY_ID]: id,
        [KEY_PUBLC_MEDIA]: datas[KEY_PUBLC_MEDIA] || [],
        [KEY_PUBLC_ATTRS]: datas[KEY_PUBLC_ATTRS] || [],
    };
    const [annotations, dictionaries] = await Promise.all([
        getAnnotationsList([id], !getAnnotations),
        getDataDictionaries([{ id, data: tabular }], columns),
    ]);
    return mergeProductDatas({
        tabularroot,
        tabular,
        ready,
        media,
        publications,
        dictionaries,
        annotations,
        attributeSets,
        categories,
        columns,
        businessIds,
    });
};
export default getProductData;
