import * as React from "react";
import { CtxUserData, CtxTenantM2ms, CtxTenantUsers } from "../contexts";
import WindowProfile from "./WindowProfile";
import ModalLogout from "./ModalLogout";
import { useHistory } from "react-router-dom";
import { logOut } from "../../api/fetchCookieJwt";
import BtnBase from "@warda/library-ui/core/BtnBase";
import { createUseStyles } from "react-jss";
import classnames from "classnames";
import hexToRgbA from "@warda/library-ui/utils/hexToRgbA";
import { getTheme } from "@warda/library-ui/theme";
import Avatar from "@warda/library-ui/core/Avatar";
import { getUser } from "@warda/library-ui/utils/getUser";
const background = hexToRgbA(getTheme().colors.theme1, 0.15);
const SIZE = 30;
const useStyles = createUseStyles({
    btnProfile: {
        height: SIZE,
        width: SIZE,
        "border-radius": getTheme().borderRadius,
        color: getTheme().colors.theme1,
        boxSizing: "content-box",
        margin: "0 1px",
        border: "1px solid transparent",
        "&:hover": {
            "background-color": background,
        },
    },
    btnProfileSelected: {
        "background-color": background,
    },
});
const BtnWindowProfile = () => {
    const classes = useStyles({});
    const users = React.useContext(CtxTenantUsers);
    const m2ms = React.useContext(CtxTenantM2ms);
    const currUser = React.useContext(CtxUserData);
    const history = useHistory();
    const userMock = getUser(currUser.userId, users, m2ms);
    const menuRef = React.useRef(null);
    const [menu, setMenu] = React.useState(false);
    const onMenuOpen = React.useCallback(() => {
        setMenu(true);
    }, []);
    const onMenuClose = React.useCallback(() => {
        setMenu(false);
    }, []);
    const [modalLogout, setModalLogout] = React.useState(false);
    const onModalLogoutClose = React.useCallback(() => {
        setModalLogout(false);
    }, []);
    const onModalLogoutOpen = React.useCallback(() => {
        setModalLogout(true);
        setMenu(false);
    }, []);
    const onLogout = React.useCallback(() => {
        logOut(history);
    }, [history]);
    return (React.createElement(React.Fragment, null,
        React.createElement(BtnBase, { ref: menuRef, className: classnames({
                [classes.btnProfile]: true,
                [classes.btnProfileSelected]: menu,
            }), onClick: onMenuOpen, tooltip: "Profile" },
            React.createElement(Avatar, { style: { borderRadius: 5, fontSize: 12 }, size: SIZE, src: userMock.avatar, text: userMock.avatarText, icon: userMock.avatarIcon })),
        React.createElement(WindowProfile, { open: menu, anchorEl: menuRef.current, onClose: onMenuClose, onModalLogout: onModalLogoutOpen }),
        React.createElement(ModalLogout, { open: modalLogout, onCancel: onModalLogoutClose, onLogout: onLogout })));
};
export default BtnWindowProfile;
