import checkCatalogsDisabled from "./utils/checkCatalogsDisabled";
import { Initialize } from "@warda/library-ui/interfaces";
export var ACTION;
(function (ACTION) {
    ACTION["RESET"] = "RESET";
    ACTION["INIT_MODAL_START"] = "INIT_MODAL_START";
    ACTION["INIT_MODAL_LOADING"] = "INIT_MODAL_LOADING";
    ACTION["INIT_MODAL_SUCC"] = "INIT_MODAL_SUCC";
    ACTION["INIT_MODAL_FAIL"] = "INIT_MODAL_FAIL";
    ACTION["INIT_CONFIRM_STARTED"] = "INIT_CONFIRM_STARTED";
    ACTION["INIT_CONFIRM_LOADING"] = "INIT_CONFIRM_LOADING";
    ACTION["INIT_CONFIRM_STOPPED"] = "INIT_CONFIRM_STOPPED";
    ACTION["CHANGE_CATALOGS"] = "CHANGE_CATALOGS";
    ACTION["CHANGE_VIEWS"] = "CHANGE_VIEWS";
    ACTION["TOGGLE_SKIP_REVIEW"] = "TOGGLE_SKIP_REVIEW";
    ACTION["TOGGLE_SKIP_READY"] = "TOGGLE_SKIP_READY";
    ACTION["TOGGLE_SKIP_CHECKED"] = "TOGGLE_SKIP_CHECKED";
    ACTION["INPUT_CATALOGS"] = "INPUT_CATALOGS";
    ACTION["INPUT_VIEWS"] = "INPUT_VIEWS";
})(ACTION || (ACTION = {}));
export const reducerInitState = {
    initModal: Initialize.NONE,
    initConfirm: Initialize.NONE,
    slcCtlgs: [],
    slcViews: [],
    error: "",
    items: [],
    inputCatalogs: "",
    inputViews: "",
    skipItemsReview: false,
    skipItemsReady: false,
    onlyItemsChecked: true,
};
const reducer = (state, action) => {
    const newState = { ...state };
    switch (action.type) {
        case ACTION.INIT_MODAL_START:
            newState.initModal = Initialize.START;
            return newState;
        case ACTION.INIT_MODAL_LOADING:
            newState.initModal = Initialize.LOADING;
            return newState;
        case ACTION.INIT_MODAL_SUCC: {
            const items = action.items;
            newState.initModal = Initialize.NONE;
            newState.slcCtlgs = action.slcCtlgs;
            newState.slcViews = action.slcViews;
            newState.items = items;
            return newState;
        }
        case ACTION.INIT_MODAL_FAIL:
            newState.initModal = Initialize.FAIL;
            newState.error = action.error;
            return newState;
        case ACTION.CHANGE_CATALOGS: {
            const multReadyOp = action.multiReadyOp;
            const changedCatalogs = action.changedCatalogs;
            const newCatalogs = action.newCatalogs;
            const changedSelectedCatalogsIdSet = new Set(changedCatalogs.reduce((acc, c) => {
                if (c.selected)
                    acc.push(c.id);
                return acc;
            }, []));
            const newViews = newState.slcViews.map((v) => {
                if (!changedSelectedCatalogsIdSet.has(v.catalog))
                    return v;
                return {
                    ...v,
                    selected: true,
                };
            });
            newState.slcViews = newViews;
            newState.slcCtlgs = checkCatalogsDisabled(multReadyOp, newCatalogs, newViews);
            return newState;
        }
        case ACTION.CHANGE_VIEWS: {
            const multReadyOp = action.multiReadyOp;
            const newViews = action.newViews;
            newState.slcViews = newViews;
            newState.slcCtlgs = checkCatalogsDisabled(multReadyOp, newState.slcCtlgs, newViews);
            return newState;
        }
        case ACTION.TOGGLE_SKIP_REVIEW:
            newState.skipItemsReview = !state.skipItemsReview;
            return newState;
        case ACTION.TOGGLE_SKIP_READY:
            newState.skipItemsReady = !state.skipItemsReady;
            return newState;
        case ACTION.TOGGLE_SKIP_CHECKED:
            newState.onlyItemsChecked = !state.onlyItemsChecked;
            return newState;
        case ACTION.INIT_CONFIRM_STARTED:
            newState.initConfirm = Initialize.START;
            return newState;
        case ACTION.INIT_CONFIRM_LOADING:
            newState.initConfirm = Initialize.LOADING;
            return newState;
        case ACTION.INIT_CONFIRM_STOPPED:
            newState.initConfirm = Initialize.NONE;
            return newState;
        case ACTION.INPUT_CATALOGS:
            newState.inputCatalogs = action.value;
            return newState;
        case ACTION.INPUT_VIEWS:
            newState.inputViews = action.value;
            return newState;
        case ACTION.RESET:
            return reducerInitState;
        default:
            console.error(`no action ${action.type}`);
            return state;
    }
};
export default reducer;
