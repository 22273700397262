import * as React from "react";
import useStyles from "../useStylesSelect";
import ListTitle from "../ListTitle";
import SelectCatalog from "./SelectCatalog";
import FieldSearch from "@warda/library-ui/core/FieldSearch";
import List from "@warda/library-ui/core/List";
import { MultiReadyOp, MultiReadyTarget, } from "../../ToolbarHeader/BtnReadys/BtnReadys";
const getDisabledReason = (id, multiReadyOp, multiReadyTarget, slcViews, viewsReady) => {
    if (multiReadyOp == MultiReadyOp.Unset)
        return undefined;
    const views = viewsReady.reduce((acc, vr) => {
        const view = slcViews.find((v) => v.view === vr && v.catalog === id);
        if (!!view && view.selected)
            acc.push(vr);
        return acc;
    }, []);
    const disabledReason = [
        `You can't deselect this Catalog because there is at least one ${multiReadyTarget == MultiReadyTarget.Media ? "media" : "document"} in Ready for it.`,
        "To deselect this Catalog, first deselect the following views: ",
        views.join(", "),
    ];
    return disabledReason;
};
const SelectCatalogs = ({ multiReadyOp, multiReadyTarget, slcCtlgs, slcViews, onChange, onSearch, inputSearch, readOnly, }) => {
    const classes = useStyles({});
    const allSelected = !slcCtlgs.find(({ selected }) => !selected);
    const onSelect = React.useCallback((id) => {
        let newItem;
        const newItems = slcCtlgs.map((item) => {
            if (id === item.id) {
                newItem = {
                    ...item,
                    selected: !item.selected,
                };
                return newItem;
            }
            return item;
        });
        onChange([newItem], newItems);
    }, [slcCtlgs, onChange]);
    const onSelectAll = React.useCallback(() => {
        const newItems = slcCtlgs.map((item) => ({
            ...item,
            selected: allSelected ? !!item.disabled : true,
        }));
        onChange(newItems, newItems);
    }, [slcCtlgs, onChange, allSelected]);
    return (React.createElement("div", { className: classes.listContainer },
        React.createElement(ListTitle, { icon: "auto_stories", title: "Catalogs", titleInfo: "In which catalogs do you want to apply the Ready status? Please select one or more catalogs", selectAllCheck: allSelected, selectAllCount: slcCtlgs.length, selectAllOnClick: onSelectAll, readOnly: readOnly }),
        React.createElement(FieldSearch, { style: { width: "auto" }, value: inputSearch, onChange: onSearch }),
        React.createElement(List, { className: classes.listContainerScroll }, slcCtlgs
            .filter(({ displayName }) => {
            const i = inputSearch.toLocaleUpperCase();
            return displayName.toLocaleUpperCase().includes(i);
        })
            .map(({ id, displayName, selected, disabled, viewsReady }) => {
            const disabledReason = getDisabledReason(id, multiReadyOp, multiReadyTarget, slcViews, viewsReady);
            return (React.createElement(SelectCatalog, { key: id, id: id, label: displayName, selected: selected, disabledReason: disabledReason, disabled: disabled, onClick: onSelect, readOnly: readOnly }));
        }))));
};
export default SelectCatalogs;
