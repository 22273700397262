import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import { createUseStyles } from "react-jss";
import Text from "@warda/library-ui/core/Text";
import Toolbar from "@warda/library-ui/core/Toolbar";
import List from "@warda/library-ui/core/List";
import { Slide } from "@warda/library-ui/core/Transitions";
import Divider from "@warda/library-ui/core/Divider";
import Btn from "@warda/library-ui/core/Btn";
import { ACT_MODAL } from "./reducer";
import Placeholder from "@warda/library-ui/core/Placeholder";
import ListSavedSelectionsItem from "./ListSavedSelectionsItem";
const useStyles = createUseStyles({
    panel: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        position: "absolute",
        zIndex: 1,
        backgroundColor: getTheme().colors.background,
        width: "100%",
        height: "100%",
    },
    toolbar: {
        backgroundColor: getTheme().colors.grayDrawer,
        padding: "0 10px 0 15px",
    },
});
const ListSavedSelections = ({ dispatchModal, open, loading, items, selections, }) => {
    const classes = useStyles({});
    const [backdrop, setBackdrop] = React.useState(false);
    const onClose = React.useCallback(() => {
        dispatchModal({ type: ACT_MODAL.SELECTIONS_LIST_OPEN });
    }, [dispatchModal]);
    const onSelect = React.useCallback((id) => {
        dispatchModal({ type: ACT_MODAL.SELECTIONS_LIST_SLCT, id });
    }, [dispatchModal]);
    const onRemove = React.useCallback((id) => {
        dispatchModal({ type: ACT_MODAL.SELECTION_DELETE, id });
    }, [dispatchModal]);
    const onRename = React.useCallback((id, label) => {
        dispatchModal({ type: ACT_MODAL.SELECTION_RENAME, id, label });
    }, [dispatchModal]);
    React.useEffect(() => {
        if (open && !selections.length)
            onClose();
    }, [onClose, open, selections.length]);
    return (React.createElement(Slide, { open: open, direction: "left" },
        React.createElement("div", { className: classes.panel },
            React.createElement(Toolbar, { className: classes.toolbar },
                React.createElement(Text, { weight: "bolder", children: "List of your saved selections" }),
                React.createElement("div", { style: { flex: 1 } }),
                React.createElement(Btn, { onClick: onClose, tooltip: "Return on single selections", icon: "u_turn_left", iconStyle: { transform: "rotate(90deg)" } })),
            React.createElement(Divider, null),
            React.createElement(List, { style: { flex: 1 } }, selections
                .sort((a, b) => {
                if (a.created > b.created)
                    return -1;
                if (a.created < b.created)
                    return +1;
                return 0;
            })
                .map((s) => (React.createElement(ListSavedSelectionsItem, { key: s.id, items: items, setBackdrop: setBackdrop, onSelect: onSelect, onRemove: onRemove, onRename: onRename, mngItem: s })))),
            React.createElement(Placeholder, { open: backdrop || loading, spinner: loading }))));
};
export default ListSavedSelections;
