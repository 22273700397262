import { Initialize } from "@warda/library-ui/interfaces";
export var ACTIONS;
(function (ACTIONS) {
    ACTIONS["RESET"] = "RESET";
    ACTIONS["INIT_CONFIRM_START"] = "INIT_CONFIRM_START";
    ACTIONS["INIT_CONFIRM_LOADING"] = "INIT_CONFIRM_LOADING";
    ACTIONS["INIT_CONFIRM_FAIL"] = "INIT_CONFIRM_FAIL";
    ACTIONS["SET_TENT"] = "SET_TENT";
    ACTIONS["SET_PASS"] = "SET_PASS";
    ACTIONS["SET_USER"] = "SET_USER";
})(ACTIONS || (ACTIONS = {}));
export const reducerInitState = {
    initConfirm: Initialize.NONE,
    username: "",
    password: "",
    tenant: "",
};
export const reducer = (state, action) => {
    const newState = { ...state };
    switch (action.type) {
        case ACTIONS.INIT_CONFIRM_START:
            newState.initConfirm = Initialize.START;
            return newState;
        case ACTIONS.INIT_CONFIRM_LOADING:
            newState.initConfirm = Initialize.LOADING;
            return newState;
        case ACTIONS.INIT_CONFIRM_FAIL:
            newState.initConfirm = Initialize.FAIL;
            return newState;
        case ACTIONS.SET_USER:
            newState.username = action.value;
            newState.initConfirm = reducerInitState.initConfirm;
            return newState;
        case ACTIONS.SET_PASS:
            newState.password = action.value;
            newState.initConfirm = reducerInitState.initConfirm;
            return newState;
        case ACTIONS.SET_TENT:
            newState.tenant = action.value;
            newState.initConfirm = reducerInitState.initConfirm;
            return newState;
        case ACTIONS.RESET:
            return reducerInitState;
        default:
            return newState;
    }
};
