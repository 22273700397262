import { ERROR_GENERIC } from "../constants";
const getErrorMsg = async (err, t = ERROR_GENERIC) => {
    try {
        if (typeof err === "string")
            return err;
        if (typeof (err === null || err === void 0 ? void 0 : err.message) === "string")
            return err.message;
        const errJson = await err.response.json();
        const errText = errJson.message || errJson.error;
        return errText || t;
    }
    catch {
        return t;
    }
};
export default getErrorMsg;
