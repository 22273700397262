import { ISortOrder, TenantStatus, } from "@warda/library-ui/interfaces";
import { ASSIGNEES_SORTS_KEY } from "../../constantsKey";
import { getUserFullname } from "@warda/library-ui/utils/getUser";
export const isEnabled = ({ tenants }, tenantId) => {
    if (!tenants || !tenants.length)
        return true;
    const tenant = tenants.find((t) => t.tenantId === tenantId);
    return tenant.status === TenantStatus.ENABLED;
};
export const sortUsersAssignments = ({ users, sortId, sortType, sortOrder, assignmentsCount, }) => {
    return users
        .map((u) => {
        const sortFullname = getUserFullname(u);
        const sortFreqCount = assignmentsCount[u.userId] || assignmentsCount[u.sub] || 0;
        return {
            ...u,
            [ASSIGNEES_SORTS_KEY.fullname]: sortFullname,
            [ASSIGNEES_SORTS_KEY.frequency]: sortFreqCount,
        };
    })
        .sort((a, b) => {
        if (!sortId)
            return 0;
        const key = sortId;
        const aValue = key ? a[key] : a;
        const bValue = key ? b[key] : b;
        const isNumber = sortType === "number";
        const order = sortOrder === ISortOrder.ASC ? -1 : 1;
        const orderFix = isNumber ? -order : order;
        if (aValue > bValue)
            return orderFix;
        if (aValue < bValue)
            return -orderFix;
        return 0;
    });
};
export const getUsersIdSearched = ({ m2ms, users, input, tenantId, disabled, }) => {
    const founds = [];
    users
        .filter((u) => {
        if (disabled)
            return true;
        return isEnabled(u, tenantId);
    })
        .filter((u) => {
        if (!input)
            return u;
        const vName = getUserFullname(u).toLocaleLowerCase();
        const vInput = input.toLocaleLowerCase();
        return vName.indexOf(vInput) !== -1;
    })
        .forEach((u) => {
        founds.push({
            id: u.userId || u.sub,
            label: getUserFullname(u),
        });
    });
    m2ms
        .filter((u) => {
        if (!input)
            return u;
        const vName = u.name.toLocaleLowerCase();
        const vInput = input.toLocaleLowerCase();
        return vName.indexOf(vInput) !== -1;
    })
        .forEach((u) => {
        founds.push({
            id: u.clientId,
            label: u.name,
        });
    });
    return founds
        .sort((a, b) => {
        if (a.label > b.label)
            return 1;
        if (a.label < b.label)
            return -1;
        return 0;
    })
        .map((u) => {
        return u.id;
    });
};
