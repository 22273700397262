import * as React from "react";
import classnames from "classnames";
import useStyles from "../useStylesSelect";
import ListCheckbox from "../ListCheckbox";
const SelectView = ({ id, label, labelSub, selected, onClick, className, readOnly, }) => {
    const classes = useStyles({});
    return (React.createElement("div", { className: classnames({
            [classes.listItem]: true,
            [className]: !!className,
        }) },
        React.createElement(ListCheckbox, { id: id, label: label, labelSub: labelSub, selected: selected, onClick: onClick, disabled: readOnly })));
};
export default SelectView;
