import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import { createUseStyles } from "react-jss";
import Btn from "@warda/library-ui/core/Btn";
import Popover from "@warda/library-ui/core/Popover";
import { FieldText } from "@warda/library-ui/core/Field";
const originAnchor = {
    vertical: "bottom",
    horizontal: "left",
};
const originTransf = {
    vertical: "top",
    horizontal: "left",
};
const useStyles = createUseStyles({
    editing: {
        display: "flex",
        "flex-direction": "column",
        padding: 10,
    },
    editingActions: {
        display: "flex",
        "flex-direction": "row",
        "margin-top": 10,
    },
    editingInput: {
        width: 350,
        margin: 0,
    },
});
const PopoverRename = ({ open, anchorEl, value, onInputChange, onInputKeyPress, onCancel, onConfirm, }) => {
    const classes = useStyles({});
    return (React.createElement(Popover, { open: open, anchorEl: anchorEl, originAnchor: originAnchor, originTransf: originTransf },
        React.createElement("div", { className: classes.editing },
            React.createElement(FieldText, { autoFocus: true, className: classes.editingInput, value: value, placeholder: "Write a new label for this selection", onChange: onInputChange, onKeyPress: onInputKeyPress }),
            React.createElement("div", { className: classes.editingActions },
                React.createElement("div", { style: { flex: 1 } }),
                React.createElement(Btn, { variant: "bold", label: "CANCEL", onClick: onCancel }),
                React.createElement(Btn, { variant: "bold", color: getTheme().colors.msgSucc, label: "SAVE", onClick: onConfirm })))));
};
export default PopoverRename;
