const getMediaLastUploaded = (medias, view, type) => medias.reduce((acc, m) => {
    const uploaded = acc ? acc.uploaded : 0;
    const checkType = !type ? true : m.mediaType === type;
    const checkView = m.view === view;
    const checkDate = uploaded < m.uploaded;
    if (checkType && checkView && checkDate)
        return m;
    return acc;
}, null);
export default getMediaLastUploaded;
