import { getTheme } from "@warda/library-ui/theme";
import { createUseStyles } from "react-jss";
export default createUseStyles({
    root: {
        width: "inherit",
        height: "inherit",
        backgroundColor: getTheme().colors.background,
    },
    title: {
        color: getTheme().colors.theme1,
        fontSize: 40,
        cursor: "default",
        margin: "0 auto 30px auto",
        userSelect: "none",
        textAlign: "center",
    },
    paper: {
        position: "absolute",
        minWidth: 400,
        maxWidth: 400,
        height: "fit-content",
        padding: 30,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        zIndex: 2,
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    toolbar: {
        padding: "0px !important",
    },
});
