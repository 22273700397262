import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import Text from "@warda/library-ui/core/Text";
import Icon from "@warda/library-ui/core/Icon";
import Toolbar from "@warda/library-ui/core/Toolbar";
import { createUseStyles } from "react-jss";
import ListCheckbox from "./ListCheckbox";
import IconHelp from "@warda/library-ui/core/IconHelp";
const useStyles = createUseStyles({
    listtitle: {
        padding: 0,
    },
    flex1: {
        flex: 1,
    },
    icon: {
        fontSize: "18px !important",
        margin: "0 5px",
    },
    selectall: {
        flex: "none",
        backgroundColor: "transparent",
        "&:hover": {
            backgroundColor: getTheme().colors.mousehover,
        },
    },
});
const ListTitle = ({ icon, title, titleInfo, selectAllCheck, selectAllCount, selectAllOnClick, readOnly, }) => {
    const classes = useStyles({});
    const [hover, setHover] = React.useState(false);
    const onMouseEnter = React.useCallback(() => {
        setHover(true);
    }, []);
    const onMouseLeave = React.useCallback(() => {
        setHover(false);
    }, []);
    return (React.createElement(Toolbar, { className: classes.listtitle, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave },
        React.createElement(Icon, { className: classes.icon, children: icon }),
        React.createElement(Text, { weight: "bolder", size: 2, children: title }),
        React.createElement(IconHelp, { open: hover, tooltip: titleInfo }),
        React.createElement("div", { className: classes.flex1 }),
        !selectAllCount ? null : (React.createElement(ListCheckbox, { id: "all", label: `Select All (${selectAllCount})`, selected: selectAllCheck, onClick: selectAllOnClick, className: classes.selectall, disabled: readOnly }))));
};
export default ListTitle;
