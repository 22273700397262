import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import Popover from "@warda/library-ui/core/Popover";
import Text from "@warda/library-ui/core/Text";
import Divider from "@warda/library-ui/core/Divider";
import List from "@warda/library-ui/core/List";
import { CtxCtlgs, CtxColumns } from "../contexts";
import ListItem from "@warda/library-ui/core/ListItem";
import classnames from "classnames";
import Btn from "@warda/library-ui/core/Btn";
import useStyles from "./useStylesLink";
const defaultOriginAnchor = {
    vertical: "top",
    horizontal: "left",
};
const defaultOriginTransf = {
    vertical: "bottom",
    horizontal: "left",
};
const getListitems = (columns, catalogs, readySets) => {
    const listitems = [];
    readySets.forEach(({ entityId, elementsReady }) => {
        const listitemEntity = {
            id: entityId,
            title: true,
            label: entityId,
        };
        listitems.push(listitemEntity);
        elementsReady.forEach((el) => {
            const col = columns.find((c) => c.attributeName === el.id);
            const listitemElement = {
                id: el.id,
                title: false,
                label: col.label,
                subLabel: el.catalogs
                    .map((cat) => {
                    if (typeof cat === "string") {
                        const ctlg = catalogs.find((c) => c.id === cat);
                        return ctlg.displayName;
                    }
                    else {
                        const ctlg = catalogs.find((c) => c.id === cat.catalogName);
                        return ctlg.displayName + "-" + cat.languages.join(",");
                    }
                })
                    .join(", "),
            };
            listitems.push(listitemElement);
        });
    });
    return listitems;
};
const LinkSummaryReady = ({ readySets, slcAttrs, menuOriginAnchor = defaultOriginAnchor, menuOriginTransf = defaultOriginTransf, }) => {
    const classes = useStyles({});
    const columns = React.useContext(CtxColumns);
    const catalogs = React.useContext(CtxCtlgs);
    const readysetsCount = readySets.reduce((acc, s) => {
        return acc + s.elementsReady.length;
    }, 0);
    const menuRef = React.useRef(null);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const onMenuOpen = React.useCallback(() => {
        setMenuOpen(true);
    }, []);
    const onMenuClose = React.useCallback(() => {
        setMenuOpen(false);
    }, []);
    if (!slcAttrs.length)
        return null;
    if (!readysetsCount) {
        return (React.createElement(Btn, { variant: "light", style: { margin: 0, maxWidth: "fit-content" }, selected: true, color: getTheme().colors.msgWarn, labelStyle: { color: getTheme().colors.msgWarn }, label: `Selected attribute${slcAttrs.length > 1 ? "s are " : " is "}already in Ready`, iconStyle: { color: getTheme().colors.msgWarn }, icon: "warning" }));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Btn, { cmpRef: menuRef, variant: "light", style: { margin: 0, maxWidth: "fit-content" }, selected: true, color: getTheme().colors.msgSucc, labelStyle: { color: getTheme().colors.msgSucc }, label: `${readysetsCount} attribute${readysetsCount > 1 ? "s" : ""} can be set Ready`, iconStyle: { color: getTheme().colors.msgSucc }, icon: "check_circle", onClick: onMenuOpen }),
        React.createElement(Popover, { open: menuOpen, anchorEl: menuRef.current, onClose: onMenuClose, originAnchor: menuOriginAnchor, originTransf: menuOriginTransf },
            React.createElement(Text, { size: 3, weight: "bolder", className: classes.title, children: "Set Ready" }),
            React.createElement(Divider, { className: classes.divider }),
            React.createElement(List, { className: classes.list }, getListitems(columns, catalogs, readySets).map(({ id, title, label, subLabel }) => (React.createElement(ListItem, { key: id, id: id, className: classnames({
                    [classes.listitem]: true,
                    [classes.listitemTitle]: title,
                }), label: label, subLabel: subLabel, subLabelStyle: { color: getTheme().colors.theme1 } })))))));
};
export default LinkSummaryReady;
