import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import { createUseStyles } from "react-jss";
import { Zoom } from "@warda/library-ui/core/Transitions";
import Btn from "@warda/library-ui/core/Btn";
import LinkSummaryAttrs from "./LinkSummaryAttrs";
import Icon from "@warda/library-ui/core/Icon";
import Text from "@warda/library-ui/core/Text";
import { CtxCtlgs, CtxColumns } from "../contexts";
import { getListitems } from "./ListCtlgs";
import classnames from "classnames";
import { getAttrsApplicable } from "./utils";
import IconHelp from "@warda/library-ui/core/IconHelp";
import PopoverRename from "./PopoverRename";
const ListitemIcon = ({ errorNoAttrsApplicable, errorNoSelectedCatalog, onClick, }) => {
    let err = "";
    if (errorNoSelectedCatalog) {
        err =
            "Unable to apply this selection because the selected entities do not have the associated catalog";
    }
    if (errorNoAttrsApplicable) {
        err =
            "Unable to apply this selection because the saved selection contains attributes that cannot be associated to selected entities";
    }
    if (errorNoSelectedCatalog && errorNoAttrsApplicable) {
        err =
            "Unable to apply this selection because the selected entities do not have the associated catalog and the saved selection contains attributes that cannot be associated to selected entities";
    }
    return !!err ? (React.createElement(IconHelp, { style: { margin: "0 4px" }, open: true, tooltip: err })) : (React.createElement(Btn, { tooltip: "Apply this selection", icon: "chevron_right", onClick: onClick }));
};
const useStyles = createUseStyles({
    listitem: {
        position: "inherit",
        display: "flex",
        flexDirection: "row",
        padding: "5px 10px 5px 0",
        alignItems: "center",
        backgroundColor: getTheme().colors.background,
        "&:hover": {
            backgroundColor: getTheme().colors.mousehover,
        },
    },
    listitemLabel: {
        padding: "5px 20px",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        flex: 1,
    },
    listitemCount: {
        marginRight: 10,
        width: 140,
        textAlign: "right",
    },
    ctlg: {
        width: 100,
        margin: "0 10px",
        display: "flex",
        flexDirection: "row",
    },
    ctlgError: {
        "& *": {
            color: `${getTheme().colors.msgFail} !important`,
        },
    },
    ctlgIcon: {
        color: getTheme().colors.typography,
        verticalAlign: "top",
    },
    ctlgText: {
        color: getTheme().colors.typography,
        margin: "0 5px",
        verticalAlign: "top",
    },
});
export var ACT_ITEM;
(function (ACT_ITEM) {
    ACT_ITEM["HOVER"] = "HOVER";
    ACT_ITEM["LABEL_EDIT_STARTED"] = "LABEL_EDIT_START";
    ACT_ITEM["LABEL_EDIT_STOPPED"] = "LABEL_EDIT_STOPPED";
    ACT_ITEM["LABEL_CHANGE"] = "LABEL_CHANGE";
})(ACT_ITEM || (ACT_ITEM = {}));
const reducerInitState = {
    hover: false,
    labelInModify: false,
    labelNewValue: "",
};
const reducer = (state, action) => {
    const newState = { ...state };
    switch (action.type) {
        case ACT_ITEM.HOVER:
            newState.hover = action.value;
            return newState;
        case ACT_ITEM.LABEL_EDIT_STARTED:
            newState.labelInModify = true;
            newState.labelNewValue = action.label;
            return newState;
        case ACT_ITEM.LABEL_EDIT_STOPPED:
            newState.labelInModify = false;
            return newState;
        case ACT_ITEM.LABEL_CHANGE:
            newState.labelNewValue = action.value;
            return newState;
        default:
            return state;
    }
};
const ListSavedSelectionsItem = ({ items, onRename, onRemove, onSelect, setBackdrop, mngItem, }) => {
    const classes = useStyles({});
    const columns = React.useContext(CtxColumns);
    const catalogs = React.useContext(CtxCtlgs);
    const labelRef = React.useRef(null);
    const [stateItem, dispatchItem] = React.useReducer(reducer, reducerInitState);
    const { hover, labelInModify, labelNewValue } = stateItem;
    const { id, payload } = mngItem;
    const { label, slcAttrs, slcCtlgs, slcLangs } = payload;
    const ctlgId = slcCtlgs[0];
    const ctlg = catalogs.find((c) => c.id === ctlgId);
    const canAttrs = getAttrsApplicable({
        columns,
        items,
        slcAttrs,
        slcCtlgs,
        slcLangs,
    });
    const errorNoAttrsApplicable = !canAttrs.length;
    const errorNoSelectedCatalog = !getListitems(items, catalogs).some((c) => c.id === ctlgId);
    const disabled = errorNoAttrsApplicable || errorNoSelectedCatalog;
    const onRemoveCb = React.useCallback(() => {
        onRemove(id);
        setBackdrop(false);
    }, [id, onRemove, setBackdrop]);
    const onLabelModifyStart = React.useCallback(() => {
        dispatchItem({ type: ACT_ITEM.LABEL_EDIT_STARTED, value: true, label });
        setBackdrop(true);
    }, [setBackdrop, label]);
    const onLabelRenamed = React.useCallback((value) => {
        dispatchItem({ type: ACT_ITEM.LABEL_EDIT_STOPPED });
        setBackdrop(false);
        if (label !== value) {
            onRename(id, value);
        }
    }, [id, label, onRename, setBackdrop]);
    const onLabelEditCancel = React.useCallback(() => {
        dispatchItem({ type: ACT_ITEM.LABEL_EDIT_STOPPED });
        setBackdrop(false);
    }, [setBackdrop]);
    const onLabelEditConfirm = React.useCallback(() => {
        onLabelRenamed(labelNewValue);
    }, [labelNewValue, onLabelRenamed]);
    const onLabelInputKeyPress = React.useCallback((keyPress, value) => {
        if (keyPress === "Enter")
            onLabelRenamed(value);
    }, [onLabelRenamed]);
    const onLabelInputChange = React.useCallback((value) => {
        dispatchItem({ type: ACT_ITEM.LABEL_CHANGE, value });
    }, []);
    const onSelectCb = React.useCallback(() => {
        if (!disabled)
            onSelect(id);
    }, [disabled, id, onSelect]);
    const onMouseEnter = React.useCallback(() => {
        dispatchItem({ type: ACT_ITEM.HOVER, value: true });
    }, []);
    const onMouseLeave = React.useCallback(() => {
        dispatchItem({ type: ACT_ITEM.HOVER, value: false });
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classes.listitem, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave },
            catalogs.length < 2 ? null : (React.createElement("div", { className: classnames({
                    [classes.ctlg]: true,
                    [classes.ctlgError]: errorNoSelectedCatalog,
                }) },
                React.createElement(Icon, { className: classes.ctlgIcon, children: "auto_stories" }),
                React.createElement(Text, { ellipsis: true, className: classes.ctlgText, children: ctlg.displayName }))),
            React.createElement("div", { ref: labelRef, style: { alignSelf: "end" } }),
            React.createElement(Text, { children: label, className: classes.listitemLabel }),
            React.createElement(Zoom, { open: hover, unmountOnExit: false },
                React.createElement(Btn, { tooltip: "Edit selection", icon: "edit", onClick: onLabelModifyStart })),
            React.createElement(Zoom, { open: hover, unmountOnExit: false },
                React.createElement(Btn, { color: getTheme().colors.msgFail, icon: "delete", tooltip: "Delete this selection", menu: {
                        onClose: onMouseLeave,
                        title: "Remove this selection?",
                        items: [
                            {
                                id: "n",
                                label: "Cancel",
                                onClick: onMouseLeave,
                            },
                            {
                                id: "y",
                                label: "Delete",
                                labelStyle: { color: getTheme().colors.msgFail },
                                onClick: onRemoveCb,
                            },
                        ],
                    } })),
            React.createElement(Text, { className: classes.listitemCount, children: React.createElement(LinkSummaryAttrs, { title: "Saved Selection", label: "attributes", items: items, slcAttrs: slcAttrs, slcCtlgs: slcCtlgs, slcLangs: slcLangs }) }),
            React.createElement(ListitemIcon, { errorNoAttrsApplicable: errorNoAttrsApplicable, errorNoSelectedCatalog: errorNoSelectedCatalog, onClick: onSelectCb })),
        React.createElement(PopoverRename, { open: labelInModify, anchorEl: labelRef.current, value: label, onInputChange: onLabelInputChange, onInputKeyPress: onLabelInputKeyPress, onConfirm: onLabelEditConfirm, onCancel: onLabelEditCancel })));
};
export default ListSavedSelectionsItem;
