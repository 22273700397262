import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import Text from "@warda/library-ui/core/Text";
import Card from "@warda/library-ui/core/Card";
import Field from "../component/Field";
import Toolbar from "@warda/library-ui/core/Toolbar";
import useStyles from "../component/useStyles";
import Placeholder from "@warda/library-ui/core/Placeholder";
import Btn from "@warda/library-ui/core/Btn";
import { useHistory } from "react-router-dom";
import setThemeDefault from "../../../utils/setThemeDefault";
const ResetPassword = ({ onResetPassword, backgroundNode }) => {
    const classes = useStyles();
    const history = useHistory();
    const [tenant, setTenant] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [successResetPassword, setSuccessResetPassword] = React.useState(false);
    const [invalidCredential, setInvalidCredential] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const cbSuccessResetPassword = React.useCallback(() => {
        setLoading(false);
        setSuccessResetPassword(true);
    }, []);
    const cbFailureResetPassword = React.useCallback(() => {
        setLoading(false);
        setInvalidCredential(true);
    }, []);
    const cbOnClickResetpassword = React.useCallback(() => {
        setLoading(true);
    }, []);
    const cbOnChangeTenant = React.useCallback((value) => {
        setTenant(value);
        setInvalidCredential(false);
    }, []);
    const cbOnChangeEmail = React.useCallback((value) => {
        setEmail(value);
        setInvalidCredential(false);
    }, []);
    const cbBackOnLogin = React.useCallback(() => {
        history.push("/");
    }, [history]);
    React.useEffect(() => {
        setThemeDefault();
    }, []);
    React.useEffect(() => {
        (async () => {
            if (loading) {
                await onResetPassword(email, tenant, cbSuccessResetPassword, cbFailureResetPassword);
                setLoading(false);
            }
        })();
    }, [
        cbFailureResetPassword,
        cbSuccessResetPassword,
        email,
        onResetPassword,
        tenant,
        loading,
    ]);
    return (React.createElement("form", { className: classes.root },
        React.createElement(Card, { className: classes.paper, elevation: 1 },
            React.createElement(Btn, { style: { position: "absolute", top: 45, left: 30, margin: 0 }, onClick: cbBackOnLogin, icon: "arrow_back" }),
            React.createElement(Text, { weight: "lighter", className: classes.title, children: "Reset Password" }),
            React.createElement(Placeholder, { open: loading, spinner: true }),
            React.createElement(Field, { autoComplete: "organization", icon: "domain", label: "Company", value: tenant, onChange: cbOnChangeTenant, readOnly: successResetPassword }),
            React.createElement(Field, { autoComplete: "username", icon: "person", label: "Email", value: email, onChange: cbOnChangeEmail, readOnly: successResetPassword }),
            React.createElement("br", null),
            React.createElement(Toolbar, { className: classes.toolbar }, successResetPassword ? (React.createElement(React.Fragment, null,
                React.createElement("div", { style: { flex: 1 } }),
                React.createElement(Text, { style: { color: getTheme().colors.msgSucc }, size: 2, weight: "bolder", children: "We have sent an email with a link to reset your password" }),
                React.createElement("div", { style: { flex: 1 } }))) : (React.createElement(React.Fragment, null,
                React.createElement("div", { style: { width: 250 } },
                    invalidCredential ? (React.createElement(Text, { style: { color: getTheme().colors.msgFail }, weight: "bolder", children: "INVALID CREDENTIALS" })) : (React.createElement(Text, { weight: "bolder", children: "IMPORTANT" })),
                    React.createElement(Text, { size: 0, children: React.createElement(React.Fragment, null,
                            "If you don\u2019t remember your email or company",
                            React.createElement("br", null),
                            "please contact your system administrator") })),
                React.createElement("div", { style: { flex: 1 } }),
                React.createElement(Btn, { variant: "bold", color: getTheme().colors.theme1, disabled: !email || !tenant, onClick: cbOnClickResetpassword, label: "RESET PASSWORD" }))))),
        backgroundNode));
};
export default ResetPassword;
