import { ViewStatus, ViewCheck, } from "../../interfaces";
import { KEY_VIEW_DATA, KEY_VIEW_STATUS, KEY_VIEW_CHECK, } from "../../constantsKey";
const getViewDetail = (item, vName) => {
    if (!item || !vName)
        return null;
    const getView = ({ viewName }) => viewName === vName;
    const vData = (item[KEY_VIEW_DATA] || []).find(getView);
    if (!vData)
        return null;
    const vStatus = (item[KEY_VIEW_STATUS] || []).find(getView);
    const vCheck = (item[KEY_VIEW_CHECK] || []).find(getView);
    const status = (vStatus && vStatus.status) || ViewStatus.DEFAULT;
    const check = (vCheck && vCheck.check) || ViewCheck.NO_CHECK;
    return {
        ...vData,
        status,
        check,
    };
};
export default getViewDetail;
