import * as React from "react";
import Toolbar from "@warda/library-ui/core/Toolbar";
import Text from "@warda/library-ui/core/Text";
import List from "@warda/library-ui/core/List";
import Divider from "@warda/library-ui/core/Divider";
import Icon from "@warda/library-ui/core/Icon";
import { ACT_MODAL } from "./reducer";
import { CtxCtlgs } from "../contexts";
import ListItem from "@warda/library-ui/core/ListItem";
import { KEY_CATALOG } from "../../../constantsKey";
import { WIDTH_CTLGS } from "./utils";
import useStyles from "./useStylesList";
import { SelectType } from "@warda/library-ui/core/Checkbox";
export const getListitems = (items, catalogs, slcCtlg) => {
    const ctlgsIds = items.reduce((acc, item) => {
        (item[KEY_CATALOG] || []).forEach((c) => {
            const ctlgMissing = !new Set(acc).has(c);
            if (ctlgMissing)
                acc.push(c);
        });
        return acc;
    }, []);
    const ctlgsIdsSet = new Set(ctlgsIds);
    return catalogs
        .filter((c) => {
        return ctlgsIdsSet.has(c.id);
    })
        .map((c) => {
        return {
            id: c.id,
            label: c.displayName,
            selected: c.id === slcCtlg,
        };
    });
};
const ListCtlgs = ({ dispatchModal, items, slcCtlg, readOnly }) => {
    const classes = useStyles({});
    const catalogs = React.useContext(CtxCtlgs);
    const listitems = getListitems(items, catalogs, slcCtlg);
    const onClick = React.useCallback((e, id) => {
        if (id !== slcCtlg) {
            dispatchModal({ type: ACT_MODAL.CTLGS_SELECTED, id });
        }
    }, [dispatchModal, slcCtlg]);
    return (React.createElement("div", { className: classes.panel, style: { width: WIDTH_CTLGS } },
        React.createElement(Toolbar, { className: classes.toolbar },
            React.createElement(Icon, { style: { marginRight: 4 }, children: "auto_stories" }),
            React.createElement(Text, { weight: "bolder", children: "Select Catalog" })),
        React.createElement(List, { style: { paddingTop: 0 } },
            React.createElement(ListItem, { style: { opacity: 0 }, className: classes.listitem, id: "all", label: " ", disabled: true, selectType: SelectType.CHECK })),
        React.createElement(Divider, { className: classes.divider }),
        React.createElement(List, { className: classes.list }, listitems.map(({ id, label, selected }) => (React.createElement(ListItem, { className: classes.listitem, key: id, id: id, label: label, selected: selected, selectType: SelectType.RADIO, onClick: onClick, disabled: readOnly }))))));
};
export default ListCtlgs;
