import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, useHistory, useLocation, } from "react-router-dom";
import { useEffect } from "react";
import { ROOT_DIV_ID, CHOOSE_PASSWORD_ID, FORGOT_PASSWORD_ID, RESET_PASSWORD_ID, SHAREPAGE_DA, AREA_SHAREPAGE, } from "./constants";
import { getTheme } from "@warda/library-ui/theme";
import WebFont from "webfontloader";
import BackgroundWithIcons from "./components/BackgroundWithIcons";
import { activateUser, forgotPassword, onLogin, onResetPasswordRequest, readQueryString, } from "./loginUtils";
import ChoosePassword from "./components/Authentication/ChoosePassword";
import Login from "./components/Authentication/Login";
import Cookies from "js-cookie";
import ResetPassword from "./components/Authentication/ResetPassword/ResetPassword";
import Main from "./components/Main";
import AreaFilesShared from "./components/AreaFilesShared_DEPRECATED";
import { REFRESH_TOKEN } from "./constantsLocalStorage";
import AreaSharepage from "./components/AreaSharepage";
const title = "SeeCommerce";
const AppRender = () => {
    const history = useHistory();
    const location = useLocation();
    const onLoginWithHistory = React.useCallback(async (username, password, groupId, cbFailure) => {
        await onLogin(username, password, groupId, cbFailure, history, location);
    }, [history, location]);
    const onResetPassword = React.useCallback(() => {
        history.push("/" + RESET_PASSWORD_ID);
    }, [history]);
    return (React.createElement(Route, { path: "/:viewId?", 
        // eslint-disable-next-line react/jsx-no-bind
        render: (routeConf) => {
            const { viewId } = routeConf.match.params;
            if (viewId === AREA_SHAREPAGE) {
                return React.createElement(AreaSharepage, null);
            }
            if (viewId === SHAREPAGE_DA) {
                return React.createElement(AreaFilesShared, null);
            }
            if (viewId === CHOOSE_PASSWORD_ID || viewId === FORGOT_PASSWORD_ID) {
                const { email, code, groupId } = readQueryString(location);
                return (React.createElement(ChoosePassword, { email: email, code: code, groupId: groupId, changePassword: viewId === CHOOSE_PASSWORD_ID ? activateUser : forgotPassword }));
            }
            if (viewId === RESET_PASSWORD_ID) {
                return (React.createElement(ResetPassword, { backgroundNode: React.createElement(BackgroundWithIcons, null), onResetPassword: onResetPasswordRequest }));
            }
            const isRefreshTokenPresent = Cookies.get(REFRESH_TOKEN);
            if (!isRefreshTokenPresent) {
                return (React.createElement(Login, { title: title, backgroundNode: React.createElement(BackgroundWithIcons, null), onLogin: onLoginWithHistory, onResetPassword: onResetPassword }));
            }
            return React.createElement(Main, { title: title, viewId: viewId });
        } }));
};
const AppInitTheme = () => {
    localStorage.setItem("lang", "en");
    useEffect(() => {
        WebFont.load(getTheme().fonts);
    }, []);
    return (React.createElement(BrowserRouter, null,
        React.createElement(AppRender, null)));
};
const container = document.getElementById(ROOT_DIV_ID);
const root = createRoot(container);
root.render(React.createElement(AppInitTheme, null));
