import * as React from "react";
import { useHistory } from "react-router-dom";
import Card from "@warda/library-ui/core/Card";
import BackgroundWithIcons from "../../BackgroundWithIcons";
import Placeholder from "@warda/library-ui/core/Placeholder";
import ContentSuccess from "./ContentSuccess";
import ContentPasswords from "./ContentPasswords";
import useStyles from "../component/useStyles";
import { PRODUCT_ID } from "../../../constantsKey";
import setThemeDefault from "../../../utils/setThemeDefault";
const initState = {
    activeSuccess: false,
    password: "",
};
var IAction;
(function (IAction) {
    IAction["SUCCESS_CHANGE_PASSWORD"] = "SUCCESS_CHANGE_PASSWORD";
    IAction["SET_PASSWORD"] = "SET_PASSWORD";
})(IAction || (IAction = {}));
const reducer = (state, action) => {
    const newState = { ...state };
    switch (action.type) {
        case IAction.SUCCESS_CHANGE_PASSWORD:
            newState.activeSuccess = true;
            newState.password = initState.password;
            return newState;
        case IAction.SET_PASSWORD:
            newState.password = action.password || initState.password;
            return newState;
        default:
            throw new Error();
    }
};
const ChoosePassword = ({ i18n: i18nInit, email, code, groupId, changePassword, }) => {
    const classes = useStyles({});
    const i18n = {
        "button.confirm": "Confirm",
        "button.gotologin": "Go to Login",
        ...i18nInit,
    };
    const history = useHistory();
    const [state, dispatch] = React.useReducer(reducer, initState);
    const { activeSuccess, password } = state;
    const onConfirm = React.useCallback((password) => {
        dispatch({ type: IAction.SET_PASSWORD, password });
    }, []);
    const onGoToLogin = React.useCallback(() => {
        history.push("/");
    }, [history]);
    React.useEffect(() => {
        (async () => {
            if (!!password) {
                try {
                    await changePassword({
                        email,
                        code,
                        groupId,
                        password,
                        applicationId: PRODUCT_ID,
                    });
                    dispatch({ type: IAction.SUCCESS_CHANGE_PASSWORD });
                }
                catch (err) {
                    console.error(err);
                }
            }
        })();
    }, [changePassword, code, email, groupId, password]);
    React.useEffect(() => {
        setThemeDefault();
    }, []);
    return (React.createElement("form", { className: classes.root },
        React.createElement(Card, { className: classes.paper, elevation: 1 },
            React.createElement(Placeholder, { open: !!password, spinner: true }),
            activeSuccess ? (React.createElement(ContentSuccess, { onConfirm: onGoToLogin, confirmLabel: i18n["button.gotologin"].toLocaleUpperCase() })) : (React.createElement(ContentPasswords, { email: email, groupId: groupId, onConfirm: onConfirm, confirmLabel: i18n["button.confirm"].toLocaleUpperCase() }))),
        React.createElement(BackgroundWithIcons, null)));
};
export default ChoosePassword;
