import { KEY_CATALOG } from "../../../constantsKey";
import { ContentType, AttributeFamily, } from "../../../interfaces";
import { isAlreadyReady, inAttributeSet, inEntityStruct, inScope, isValued, } from "../PanelDetail/filterColumns";
export const WIDTH_CTLGS = 170;
export const WIDTH_LANGS = 170;
export const WIDTH_ATTRS = 370;
export const HEIGHT = 470;
const canAttrSetReady = (column, item, ctlgId, langId) => {
    const notReady = !isAlreadyReady(column, item, ctlgId, langId);
    const valued = isValued(column, item, ctlgId, langId);
    return notReady && valued;
};
const getElementReadyCatalogs = ({ column, item, slcCtlgs, slcLangs, }) => {
    const { multiCatalog, multiLanguage } = column;
    if (multiLanguage) {
        const rCtlgs = [];
        slcCtlgs.forEach((ctlgId) => {
            const languages = slcLangs.filter((langId) => canAttrSetReady(column, item, ctlgId, langId));
            if (!!languages.length) {
                rCtlgs.push({
                    catalogName: ctlgId,
                    languages,
                });
            }
        });
        return rCtlgs;
    }
    if (multiCatalog) {
        return slcCtlgs.filter((ctlgId) => canAttrSetReady(column, item, ctlgId));
    }
    if (canAttrSetReady(column, item)) {
        return item[KEY_CATALOG] || [];
    }
    return [];
};
export const getColumnsCanBeReady = ({ columns, items, slcCtlgs, slcLangs, }) => {
    const attrsIds = items.reduce((acc, item) => {
        columns
            .filter((c) => inEntityStruct(c, item))
            .filter((c) => inAttributeSet(c, item))
            .filter((c) => inScope(c, slcCtlgs))
            .filter((c) => (c.multiLanguage ? !!slcLangs.length : true))
            .filter((c) => c.exportable)
            .filter((c) => c.attributeFamily !== AttributeFamily.MASTER)
            .forEach((c) => acc.push(c.id));
        return acc;
    }, []);
    const attrsIdsUnic = Array.from(new Set(attrsIds));
    return attrsIdsUnic.map((id) => columns.find((c) => c.id === id));
};
export const getAttrsApplicable = ({ columns, items, slcAttrs, slcCtlgs, slcLangs, }) => {
    const slcAttrsSet = new Set(slcAttrs);
    return getColumnsCanBeReady({
        columns,
        items,
        slcCtlgs,
        slcLangs,
    })
        .filter((c) => {
        return slcAttrsSet.has(c.id);
    })
        .map((c) => {
        return c.id;
    });
};
export const getReadySets = ({ columns, items, slcCtlgs, slcLangs, slcAttrs, }) => {
    return items.reduce((acc, item) => {
        // ottengo le colonne che posso mettere in ready
        const elementsReady = getColumnsCanBeReady({
            columns,
            items: [item],
            slcCtlgs,
            slcLangs,
        })
            .filter((c) => {
            // tengo le colonne che matchano con gli ids selezionati
            return new Set(slcAttrs).has(c.id);
        })
            .map((c) => {
            //preparo il data
            return {
                idType: ContentType.ATTRIBUTE,
                id: c.attributeName,
                catalogs: getElementReadyCatalogs({
                    column: c,
                    item,
                    slcCtlgs,
                    slcLangs,
                }),
            };
        })
            .filter((c) => {
            // tengo i data che hanno almeno un catalogo su cui metter ready
            return !!c.catalogs.length;
        });
        // filtro gli items che non hanno attributi da metter in ready
        if (!elementsReady.length)
            return acc;
        //preparo il data
        acc.push({
            entityId: item.id,
            version: items.length > 1 ? -1 : item.version,
            elementsReady,
        });
        return acc;
    }, []);
};
export const canItWork = (columns, catalogs, items) => {
    if (items.length === 0)
        return true;
    const attrsIds = [];
    const ctlgsIds = [];
    items.forEach((item) => {
        columns
            .filter((c) => inEntityStruct(c, item))
            .filter((c) => inAttributeSet(c, item))
            .filter((c) => c.exportable)
            .filter((c) => c.attributeFamily !== AttributeFamily.MASTER)
            .forEach((c) => attrsIds.push(c.id));
        (item[KEY_CATALOG] || [])
            .filter((c) => catalogs.some((cat) => cat.id === c))
            .forEach((c) => ctlgsIds.push(c));
    });
    const hasAttrs = !!Array.from(new Set(attrsIds)).length;
    const hasCtlgs = !!Array.from(new Set(ctlgsIds)).length;
    return hasAttrs && hasCtlgs;
};
