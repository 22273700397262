import * as React from "react";
import { FilterSelect } from "@warda/library-ui/core/Filter";
import { CtxUserData, CtxTenantM2ms, CtxTenantUsers } from "../../contexts";
import getAggregations from "../../../utils/getAggregations";
import reducer, { reducerInitState, ACTION } from "./reducer";
import { Initialize } from "@warda/library-ui/interfaces";
import { getUsersIdSearched } from "../../AreaUsers/getUsersSearched";
import { getUser } from "@warda/library-ui/utils/getUser";
import { getFilterAggregation, } from "../utils";
const FilterSearchUsers = (p) => {
    const { id, label, labelSub, type, value = [], onChange, onClickCount, searchEs, className, collapsed, collapsedHelp, slcCtlg, slcLang, mandatory, } = p;
    const [state, dispatch] = React.useReducer(reducer, reducerInitState);
    const { initialize, options, optionsMore, inputValue } = state;
    const { tenantId } = React.useContext(CtxUserData);
    const m2ms = React.useContext(CtxTenantM2ms);
    const users = React.useContext(CtxTenantUsers);
    const onSearch = React.useCallback((inputValue) => {
        dispatch({ type: ACTION.SET_INPUT, inputValue });
    }, []);
    const filterAggregations = React.useMemo(() => [getFilterAggregation(p)], [p]);
    React.useEffect(() => {
        (async () => {
            if (initialize === Initialize.START) {
                dispatch({ type: ACTION.INIT_LOADING });
                let options = reducerInitState.options;
                let optionsMore = reducerInitState.optionsMore;
                try {
                    const usersIdMatched = getUsersIdSearched({
                        m2ms,
                        users,
                        input: inputValue,
                        tenantId,
                        disabled: true,
                    });
                    const [{ items, sum_other_doc_count }] = await getAggregations({
                        include: usersIdMatched,
                        searchEs,
                        filterAggregations,
                        slcCtlg,
                        slcLang,
                    });
                    options = items
                        .filter(({ key }) => {
                        return !!key;
                    })
                        .sort((a, b) => {
                        const aKey = a.doc_count;
                        const bKey = b.doc_count;
                        if (aKey > bKey)
                            return -1;
                        if (aKey < bKey)
                            return +1;
                        return 0;
                    })
                        .map(({ key, doc_count }) => {
                        const user = getUser(key, users, m2ms);
                        return {
                            id: key,
                            label: user.name,
                            count: doc_count,
                        };
                    });
                    optionsMore = sum_other_doc_count;
                }
                catch (err) {
                    console.log({ id: filterAggregations[0].id, err });
                }
                dispatch({ type: ACTION.INIT_SUCC, options, optionsMore });
            }
        })();
    }, [
        filterAggregations,
        initialize,
        inputValue,
        m2ms,
        searchEs,
        slcCtlg,
        slcLang,
        tenantId,
        users,
    ]);
    return (React.createElement(FilterSelect, { id: id, type: type, label: label, labelSub: labelSub, onChange: onChange, onSearch: onSearch, onClickCount: onClickCount, options: options, optionsMore: optionsMore, value: value.map((v) => v.id), className: className, collapsed: collapsed, collapsedHelp: collapsedHelp, mandatory: mandatory, loadingSearch: initialize === Initialize.LOADING }));
};
export default FilterSearchUsers;
