import { createUseStyles } from "react-jss";
import { getTheme } from "@warda/library-ui/theme";
const useStyles = createUseStyles({
    listContainer: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        width: 270,
        height: 350,
        position: "relative",
    },
    listContainerScroll: {
        flex: 1,
        width: "-webkit-fill-available",
    },
    listContainerGroupTitle: {
        marginLeft: 5,
    },
    listItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderRadius: 5,
        backgroundColor: getTheme().colors.background,
        "&:hover": {
            backgroundColor: getTheme().colors.mousehover,
        },
    },
    listItemSelectAll: {
        "& *": {
            fontWeight: "bold",
            fontSize: 11,
        },
    },
    listItemNested: {
        marginLeft: 15,
        borderRadius: 5,
        backgroundColor: getTheme().colors.background,
        "&:hover": {
            backgroundColor: getTheme().colors.mousehover,
        },
    },
    listItemLabelDisabled: {
        fontStyle: "italic",
        opacity: 0.3,
        marginRight: 10,
    },
});
export default useStyles;
