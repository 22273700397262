import * as React from "react";
import Text from "@warda/library-ui/core/Text";
import classnames from "classnames";
import useStyles from "../useStylesSelect";
import ListCheckbox from "../ListCheckbox";
import IconHelp from "@warda/library-ui/core/IconHelp";
const SelectCatalog = ({ id, label, disabledReason = [], disabled = false, selected = false, onClick, className = "", readOnly, }) => {
    const classes = useStyles({});
    return (React.createElement("div", { className: classnames({
            [classes.listItem]: true,
            [className]: !!className,
        }) },
        React.createElement(ListCheckbox, { id: id, label: label, disabled: disabled || readOnly, selected: selected, onClick: onClick }),
        !disabled ? null : (React.createElement(React.Fragment, null,
            disabledReason.length > 0 ? (React.createElement(IconHelp, { tooltip: disabledReason })) : null,
            React.createElement(Text, { className: classes.listItemLabelDisabled, children: "Can't deselect" })))));
};
export default React.memo(SelectCatalog);
