import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import { createUseStyles } from "react-jss";
import Icon from "@warda/library-ui/core/Icon";
import Text from "@warda/library-ui/core/Text";
import BtnBase from "@warda/library-ui/core/BtnBase";
import classnames from "classnames";
const useStyles = createUseStyles({
    checkbox: {
        display: "flex",
        "flex-direction": "row",
        "justify-content": "start",
        "align-items": "center",
        margin: 0,
        padding: "0 5px",
        "border-radius": 5,
        outline: "none",
        flex: 1,
        "min-height": 30,
        cursor: ({ disabled }) => (disabled ? "default" : "pointer"),
    },
    icon: {
        "margin-right": 5,
        color: ({ selected, disabled }) => {
            if (disabled)
                return getTheme().colors.disable;
            if (selected)
                return getTheme().colors.theme1;
            return getTheme().colors.grayBorder;
        },
    },
    labelSub: {
        color: getTheme().colors.disable,
        "margin-left": 5,
    },
});
const ListCheckbox = ({ id, label, labelSub, disabled = false, selected = false, onClick, className, }) => {
    const classes = useStyles({
        disabled,
        selected,
    });
    const cbOnClick = React.useCallback(() => {
        onClick(id);
    }, [id, onClick]);
    return (React.createElement(BtnBase, { className: classnames({
            [classes.checkbox]: true,
            [className]: !!className,
        }), onClick: disabled ? undefined : cbOnClick },
        React.createElement(Icon, { className: classes.icon, children: selected ? "check_box" : "check_box_outline_blank" }),
        React.createElement(Text, { children: label }),
        React.createElement(Text, { className: classes.labelSub, size: 0, children: labelSub }),
        React.createElement("div", { style: { flex: 1 } })));
};
export default ListCheckbox;
