import { createUseStyles } from "react-jss";
const useStyles = createUseStyles({
    title: {
        padding: "10px 15px",
    },
    divider: {
        margin: "0 15px",
    },
    list: {
        "max-height": 300,
        "min-width": 165,
        overflow: "auto",
    },
    listitem: {
    // "background-color": "#fff !important",
    // cursor: "default !important",
    },
    listitemTitle: {
        "& *": {
            "font-weight": "bold",
        },
    },
});
export default useStyles;
