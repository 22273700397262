import * as React from "react";
import Text from "@warda/library-ui/core/Text";
import Btn from "@warda/library-ui/core/Btn";
import { getTheme } from "@warda/library-ui/theme";
import FieldPassword from "./FieldPassword";
import Toolbar from "@warda/library-ui/core/Toolbar";
import useStyles from "../component/useStyles";
import { Initialize } from "@warda/library-ui/interfaces";
import { getPasswordPolicy } from "../../../api/fetchesApiAnonymous";
const SPECIAL_CHARS = [
    "^",
    "$",
    "*",
    ".",
    "[",
    "]",
    "{",
    "}",
    "(",
    ")",
    "?",
    "!",
    "@",
    "#",
    "%",
    "&",
    ">",
    "<",
    ":",
    ";",
    "|",
    "_",
    "=",
    "+",
    "-",
];
const haveValidMinLength = (required, pass, n) => {
    return !required ? true : pass.length >= n;
};
const haveValidMaxLength = (required, pass, n) => {
    return !required ? true : pass.length <= n;
};
const haveValidLowercase = (required, pass) => {
    return !required ? true : /[a-z]/.test(pass);
};
const haveValidUppercase = (required, pass) => {
    return !required ? true : /[A-Z]/.test(pass);
};
const haveValidNumbers = (required, pass) => {
    return !required ? true : /[0-9]/.test(pass);
};
const haveValidSymbols = (required, pass) => {
    return !required ? true : SPECIAL_CHARS.some((s) => pass.indexOf(s) > -1);
};
const addValidationLabel = (require, valid, text) => {
    if (!require)
        return "";
    return (valid ? "✅" : "❌") + " " + text;
};
const initState = {
    passwordOne: "",
    passwordTwo: "",
    initRequire: Initialize.START,
    requireMinLength: 0,
    requireMaxLength: 0,
    requireLowercase: false,
    requireUppercase: false,
    requireNumbers: false,
    requireSymbols: false,
    validMinLength: false,
    validMaxLength: false,
    validLowercase: false,
    validUppercase: false,
    validNumbers: false,
    validSymbols: false,
};
var ACT;
(function (ACT) {
    ACT["INIT_REQUIRE_LOADING"] = "INIT_REQUIRE_LOADING";
    ACT["INIT_REQUIRE_SUCC"] = "INIT_REQUIRE_SUCC";
    ACT["INIT_REQUIRE_FAIL"] = "INIT_REQUIRE_FAIL";
    ACT["EDITING"] = "EDITING";
})(ACT || (ACT = {}));
const reducer = (state, action) => {
    const newState = { ...state };
    switch (action.type) {
        case ACT.INIT_REQUIRE_LOADING:
            newState.initRequire = Initialize.LOADING;
            return newState;
        case ACT.INIT_REQUIRE_SUCC:
            newState.initRequire = Initialize.SUCC;
            newState.requireMinLength = action.requireMinLength;
            newState.requireMaxLength = action.requireMaxLength;
            newState.requireLowercase = action.requireLowercase;
            newState.requireUppercase = action.requireUppercase;
            newState.requireNumbers = action.requireNumbers;
            newState.requireSymbols = action.requireSymbols;
            return newState;
        case ACT.INIT_REQUIRE_FAIL:
            newState.initRequire = Initialize.FAIL;
            return newState;
        case ACT.EDITING:
            if (action.passwordOne !== undefined) {
                newState.passwordOne = action.passwordOne;
                newState.validMinLength = haveValidMinLength(!!newState.requireMinLength, newState.passwordOne, newState.requireMinLength);
                newState.validMaxLength = haveValidMaxLength(!!newState.requireMaxLength, newState.passwordOne, newState.requireMaxLength);
                newState.validLowercase = haveValidLowercase(newState.requireLowercase, newState.passwordOne);
                newState.validUppercase = haveValidUppercase(newState.requireUppercase, newState.passwordOne);
                newState.validNumbers = haveValidNumbers(newState.requireNumbers, newState.passwordOne);
                newState.validSymbols = haveValidSymbols(newState.requireSymbols, newState.passwordOne);
            }
            if (action.passwordTwo !== undefined) {
                newState.passwordTwo = action.passwordTwo;
            }
            return newState;
        default:
            return state;
    }
};
const ContentPasswords = ({ email, groupId, onConfirm, confirmLabel, }) => {
    const classes = useStyles({});
    const [state, dispatch] = React.useReducer(reducer, initState);
    const { passwordOne, passwordTwo, initRequire, requireMinLength, requireMaxLength, requireLowercase, requireUppercase, requireNumbers, requireSymbols, validMinLength, validMaxLength, validLowercase, validUppercase, validNumbers, validSymbols, } = state;
    const onChangePasswordOne = React.useCallback((v) => {
        dispatch({ type: ACT.EDITING, passwordOne: v });
    }, []);
    const onChangePasswordTwo = React.useCallback((v) => {
        dispatch({ type: ACT.EDITING, passwordTwo: v });
    }, []);
    const onClickBtnConfirm = React.useCallback(() => {
        onConfirm(passwordOne);
    }, [passwordOne, onConfirm]);
    const validPasswordOne = validMinLength &&
        validMaxLength &&
        validLowercase &&
        validUppercase &&
        validNumbers &&
        validSymbols;
    const validPasswordTwo = validPasswordOne
        ? passwordOne === passwordTwo
        : true;
    React.useEffect(() => {
        (async () => {
            if (initRequire === Initialize.START) {
                try {
                    dispatch({ type: ACT.INIT_REQUIRE_LOADING });
                    const policy = await getPasswordPolicy(groupId);
                    dispatch({
                        type: ACT.INIT_REQUIRE_SUCC,
                        requireMinLength: policy.minimumLength,
                        requireMaxLength: 256,
                        requireLowercase: policy.requireLowercase,
                        requireUppercase: policy.requireUppercase,
                        requireNumbers: policy.requireNumbers,
                        requireSymbols: policy.requireSymbols,
                    });
                }
                catch {
                    dispatch({ type: ACT.INIT_REQUIRE_FAIL });
                }
            }
        })();
    }, [groupId, initRequire]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Text, { className: classes.title, children: "\uD83D\uDD11" }),
        React.createElement(Text, { weight: "lighter", children: React.createElement(React.Fragment, null,
                "Please choose Warda password for ",
                React.createElement("b", null, email)) }),
        React.createElement("div", { style: { flex: 1 } }),
        React.createElement(FieldPassword, { tooltip: [
                addValidationLabel(!!requireMinLength, validMinLength, `Minimum password length of ${requireMinLength} characters`),
                addValidationLabel(!!requireMaxLength, validMaxLength, `Maximum password length of ${requireMaxLength} characters`),
                addValidationLabel(!!requireLowercase, validLowercase, "Password must contain a lower case"),
                addValidationLabel(!!requireUppercase, validUppercase, "Password must contain a upper case"),
                addValidationLabel(!!requireNumbers, validNumbers, "Password must contain at least one number"),
                addValidationLabel(!!requireSymbols, validSymbols, "Password must contain at least one symbol"),
            ], disabled: initRequire === Initialize.LOADING, valid: !validPasswordOne, value: passwordOne, placeholder: "Type password", onChange: onChangePasswordOne, autoComplete: "current-password" }),
        React.createElement(FieldPassword, { tooltip: ["❌ Password doesn't match"], disabled: initRequire === Initialize.LOADING, valid: !validPasswordTwo, value: passwordTwo, placeholder: "Re-type password", onChange: onChangePasswordTwo, autoComplete: "new-password" }),
        React.createElement("div", { style: { flex: 1 } }),
        React.createElement(Toolbar, { className: classes.toolbar },
            React.createElement("div", { style: { flex: 1 } }),
            React.createElement(Btn, { variant: "bold", color: getTheme().colors.theme1, disabled: !validPasswordOne || !validPasswordTwo, onClick: onClickBtnConfirm, label: confirmLabel }))));
};
export default ContentPasswords;
