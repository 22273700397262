import * as React from "react";
import ListItem from "@warda/library-ui/core/ListItem";
import { NavLink } from "react-router-dom";
import hexToRgbA from "@warda/library-ui/utils/hexToRgbA";
import { getTheme } from "@warda/library-ui/theme";
const WindowSections = ({ id, label, icon, disabled, sectionId, onClose, }) => {
    const node = React.useMemo(() => (React.createElement(ListItem, { key: id, id: id, label: label, icon: icon, style: {
            background: id === sectionId
                ? hexToRgbA(getTheme().colors.theme1, 0.15)
                : undefined,
        }, selected: id === sectionId, disabled: disabled, onClick: onClose, clickPropagation: true })), [disabled, icon, id, sectionId, label, onClose]);
    return disabled ? (node) : (React.createElement(NavLink, { key: id, style: {
            display: "block",
            textDecoration: "none",
            color: "inherit",
        }, to: `/${id}/`, children: node }));
};
export default WindowSections;
