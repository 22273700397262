import { getTheme } from "@warda/library-ui/theme";
import { createUseStyles } from "react-jss";
const useStyles = createUseStyles({
    panel: {
        display: "flex",
        "flex-direction": "column",
        "align-items": "stretch",
        position: "relative",
        overflow: "hidden",
        "border-right": `1px solid ${getTheme().colors.grayBorder}`,
    },
    list: {
        position: "relative",
        height: "100%",
        "overflow-y": "overlay",
    },
    listitem: {
        padding: "0 20px",
    },
    divider: {
        margin: "0 15px",
    },
    toolbar: {
        padding: "0 20px",
    },
    input: {
        width: "-webkit-fill-available",
        margin: "0 10px",
        border: 0,
        "border-radius": 0,
        "min-height": 50,
        "box-shadow": "0 7px 5px -5px rgb(0 0 0 / 15%)",
        "z-index": 1,
    },
});
export default useStyles;
