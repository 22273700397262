import * as React from "react";
import Btn from "@warda/library-ui/core/Btn";
import ModalMediaReady from "../../ModalMediaReady";
import { CtxPermissions } from "../../../contexts";
import PERMS from "../../../../permissions";
import hasPerms from "../../../../utils/hasPerms";
import ModalAttributesReady from "../../ModalAttributesReady";
import { isDev, MAX_PRODUCTS_MASSIVE_ACTIONS } from "../../../../constants";
import { getTheme } from "@warda/library-ui/theme";
const unsetReadyEnabled = isDev; // TODO SEECOMM-5009 costante da eliminare
export var MultiReadyOp;
(function (MultiReadyOp) {
    MultiReadyOp["Set"] = "Set";
    MultiReadyOp["Unset"] = "Unset";
})(MultiReadyOp || (MultiReadyOp = {}));
export var MultiReadyTarget;
(function (MultiReadyTarget) {
    MultiReadyTarget["Media"] = "Media";
    MultiReadyTarget["Documents"] = "Documents";
    MultiReadyTarget["Attributes"] = "Attributes";
})(MultiReadyTarget || (MultiReadyTarget = {}));
var ACTION;
(function (ACTION) {
    ACTION["RESET"] = "RESET";
    ACTION["SET"] = "SET";
})(ACTION || (ACTION = {}));
const reducerInitState = {
    multiReadyTarget: null,
    multiReadyOp: null,
};
const reducer = (state, action) => {
    const newState = { ...state };
    switch (action.type) {
        case ACTION.SET:
            newState.multiReadyTarget = action.multiReadyTarget;
            newState.multiReadyOp = action.multiReadyOp;
            return newState;
        case ACTION.RESET:
            return reducerInitState;
        default:
            return state;
    }
};
const LABEL = unsetReadyEnabled ? "Set/Unset Ready" : "Set Ready";
const BtnReadys = ({ minimal, itemsIdSelected, slcCtlg, slcLang, }) => {
    const permissions = React.useContext(CtxPermissions);
    const [state, dispatch] = React.useReducer(reducer, reducerInitState);
    const { multiReadyTarget, multiReadyOp } = state;
    const onClose = React.useCallback(() => {
        dispatch({ type: ACTION.RESET });
    }, []);
    const onOpen = React.useCallback((multiReady) => {
        dispatch({ type: ACTION.SET, ...multiReady });
    }, []);
    const menuItems = React.useMemo(() => {
        const actions = [];
        const permMedia = hasPerms([PERMS.seecommerce_manage_media_ready], permissions);
        const permDocuments = hasPerms([PERMS.seecommerce_manage_document_ready], permissions);
        const permAttribute = hasPerms([PERMS.seecommerce_manage_attribute_ready], permissions);
        const dividerforUnsetActions = permMedia || permDocuments;
        if (permMedia) {
            actions.push({
                id: "setMedia",
                icon: "image",
                label: unsetReadyEnabled ? "Set Media" : "Media",
                onClick: () => onOpen({
                    multiReadyTarget: MultiReadyTarget.Media,
                    multiReadyOp: MultiReadyOp.Set,
                }),
            });
        }
        if (permDocuments) {
            actions.push({
                id: "setDocuments",
                icon: "insert_drive_file",
                label: unsetReadyEnabled ? "Set Documents" : "Documents",
                onClick: () => onOpen({
                    multiReadyTarget: MultiReadyTarget.Documents,
                    multiReadyOp: MultiReadyOp.Set,
                }),
            });
        }
        if (permAttribute) {
            actions.push({
                id: "setAttributes",
                icon: "text_fields",
                label: unsetReadyEnabled ? "Set Attributes" : "Attributes",
                onClick: () => onOpen({
                    multiReadyTarget: MultiReadyTarget.Attributes,
                    multiReadyOp: MultiReadyOp.Set,
                }),
            });
        }
        if (unsetReadyEnabled) {
            if (dividerforUnsetActions) {
                actions.push({
                    id: "divider_unset",
                    label: "divider",
                    divider: true,
                    hidden: true,
                });
            }
            if (permMedia) {
                actions.push({
                    id: "unsetMedia",
                    icon: "image_not_supported",
                    label: "Unset Media",
                    labelStyle: { color: getTheme().colors.msgFail },
                    iconStyle: { color: getTheme().colors.msgFail },
                    onClick: () => onOpen({
                        multiReadyTarget: MultiReadyTarget.Media,
                        multiReadyOp: MultiReadyOp.Unset,
                    }),
                });
            }
            if (permDocuments) {
                actions.push({
                    id: "unsetDocuments",
                    icon: "no_sim",
                    label: "Unset Documents",
                    labelStyle: { color: getTheme().colors.msgFail },
                    iconStyle: { color: getTheme().colors.msgFail },
                    onClick: () => onOpen({
                        multiReadyTarget: MultiReadyTarget.Documents,
                        multiReadyOp: MultiReadyOp.Unset,
                    }),
                });
            }
        }
        return actions;
    }, [onOpen, permissions]);
    if (!menuItems.length || !itemsIdSelected.length)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(Btn, { icon: "public", label: minimal ? undefined : LABEL, tooltip: minimal ? LABEL : undefined, disabled: itemsIdSelected.length > MAX_PRODUCTS_MASSIVE_ACTIONS, menu: {
                title: minimal ? LABEL : undefined,
                items: menuItems,
            } }),
        (() => {
            switch (multiReadyTarget) {
                case MultiReadyTarget.Media:
                case MultiReadyTarget.Documents:
                    return (React.createElement(ModalMediaReady, { open: true, onClose: onClose, itemsIdSelected: itemsIdSelected, multiReadyOp: multiReadyOp, multiReadyTarget: multiReadyTarget }));
                case MultiReadyTarget.Attributes:
                    return (React.createElement(ModalAttributesReady, { open: true, onClose: onClose, itemsIdSelected: itemsIdSelected, slcCtlg: slcCtlg, slcLang: slcLang }));
                default:
                    return null;
            }
        })()));
};
export default BtnReadys;
