import apiUrls from "../../api/apiUrls";
import { searchEs } from "./useSearchEs";
import { fetchCookieJwtWithRefreshToken } from "../../api/fetchCookieJwt";
import getProductData from "./getProductData";
const getProducts = async ({ ids, columns, categories, source, }) => {
    if (ids.length === 1) {
        const { url, method } = apiUrls.getProduct;
        const datas = await fetchCookieJwtWithRefreshToken({
            url: url(ids[0]),
            method,
        });
        const item = await getProductData({
            datas,
            columns,
            categories,
            getAnnotations: true,
        });
        return [item];
    }
    else {
        const { items } = await searchEs({
            searchEs: { query: { terms: { _id: ids } }, _source: source },
            columns,
            categories,
        });
        return items;
    }
};
export default getProducts;
