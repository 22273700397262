import { KEY_VIEW_DATA } from "../../../../constantsKey";
import { MultiReadyOp, } from "../../ToolbarHeader/BtnReadys/BtnReadys";
import getViewsReady from "./getViewsReady";
import multyReadyTargetToViewType from "./multiReadyTargetToViewType";
/**
 * Function that return catalogs configurations to create checkboxes.
 * It cycles on items, looking at the available catalogs of the tenant,
 * to obtain all the catalogs configurations in which it is possible to set/unset medias in Ready Status
 */
const getMultiReadyCtlgs = ({ multiReadyOp, multiReadyTarget, items, tntCtlgs, tntViews, }) => {
    const viewType = multyReadyTargetToViewType(multiReadyTarget);
    const tntSingleCtlg = tntCtlgs.length === 1;
    const setCatalogIds = new Set([]);
    // get all avaible catalog ids from the items
    items.forEach((item) => {
        const viewsStatus = item[KEY_VIEW_DATA] || [];
        viewsStatus
            .filter((viewStatus) => viewStatus.viewDataType === viewType)
            .forEach(({ catalog }) => {
            (catalog || []).forEach((cat) => {
                if (!setCatalogIds.has(cat))
                    setCatalogIds.add(cat);
            });
        });
    }, []);
    // get catalog configurations
    const selectCtlgs = tntCtlgs.filter((c) => {
        return setCatalogIds.has(c.id);
    });
    switch (multiReadyOp) {
        case MultiReadyOp.Unset:
        case MultiReadyOp.Set:
            return selectCtlgs.map((c) => {
                // if at least one media is Ready for this catalog, the this checkbox must be selected and disabled
                const views = tntViews.filter((view) => view.viewType == viewType);
                const viewsReady = getViewsReady({
                    ctlgId: c.id,
                    items,
                    views,
                    viewType: viewType,
                });
                return {
                    id: c.id,
                    displayName: c.displayName,
                    languages: c.languages,
                    fallback: c.fallback,
                    viewsReady,
                    disabled: multiReadyOp === MultiReadyOp.Set && !!viewsReady.length,
                    selected: tntSingleCtlg ? true : !!viewsReady.length,
                };
            });
        default:
            throw `Unmanaged op ${multiReadyOp} in getMultiReadyCtlgs`;
    }
};
export default getMultiReadyCtlgs;
