import { RENDITIONS, SearchMode, } from "../interfaces";
import { TYPE_FOLDER } from "../constants";
import apiUrls from "./apiUrlsAnonymous";
import { Service } from "@warda/library-ui/interfaces";
import { SEARCH_FILES_RESULT_ERROR, composeFolderPath, normalizeMediaLibrary, } from "./fetchesApiMediaLibrary";
import getErrorMsg from "../utils/getErrorMsg";
import { getAccessCode } from "../components/AreaSharepage/constants";
import { normalizeElasticSrc } from "../utils/elasticsearch";
const fetchAnonymous = async ({ url, method = "GET", jsonBody = {}, headers: headersAdd = {}, }) => {
    try {
        if (!!!!Object.keys(headersAdd).length) {
            console.log("NOTE: portals the headers must be passed by the BE for cookie management", headersAdd);
        }
        const headers = { "Content-Type": "application/json", ...headersAdd };
        const options = method !== "GET" && url !== "HEAD"
            ? {
                credentials: "include",
                method,
                headers,
                body: JSON.stringify(jsonBody),
            }
            : { credentials: "include", method, headers };
        const response = await fetch(url, options);
        const { status, statusText } = response;
        if (status >= 400)
            throw { status, statusText, response };
        return await response.json();
    }
    catch (err) {
        throw err;
    }
};
export const getPasswordPolicy = async (groupId) => {
    const { url, method } = apiUrls.getPasswordPolicy;
    const res = await fetchAnonymous({
        url: url(groupId),
        method,
    });
    return res;
};
export const anonymousSearchFilesDA = async (jsonBody) => {
    const { url, method } = apiUrls.searchFilesShared;
    const { items, total, errorCode, name } = await fetchAnonymous({
        url: url(),
        method,
        jsonBody,
    });
    if (!!errorCode || name === "error")
        throw errorCode || name;
    const itemsFix = items.map((d) => {
        const item = { ...d, id: d.fileid };
        delete item.fileid;
        return item;
    });
    return {
        items: itemsFix,
        itemsTotal: total,
    };
};
export const anonymousMassiveDownload = async ({ correlationId, connectionId, downloadItems, currentFolders, tenantId, accessCode, }) => {
    if (!connectionId) {
        throw { message: "Missing webSocket ID" };
    }
    if (!correlationId) {
        throw { message: "CorrelationId is empty" };
    }
    let descendents = [];
    const folders = downloadItems.filter((d) => d.mimeType === TYPE_FOLDER);
    if (folders.length !== 0) {
        const apiDescendants = apiUrls.descendantsAnonymous;
        const descendantsUrl = apiDescendants.url(Service.DA, tenantId);
        const descendantsMethod = apiDescendants.method;
        const result = await fetchAnonymous({
            url: descendantsUrl,
            method: descendantsMethod,
            jsonBody: {
                folders: folders.map((f) => f.id),
                accessCode,
            },
        });
        descendents = result.descendents.filter((f) => f.mimetype !== TYPE_FOLDER);
    }
    const folderIdsToRemove = new Set(currentFolders.map((c) => c.id));
    const mediaContentsFromDescendants = descendents.map((d) => ({
        fileId: d.documentrepoid,
        path: d.path
            .filter((o) => !folderIdsToRemove.has(o.id))
            .map((o) => o.name),
        customName: d.name,
    }));
    const filesInCurrentFolder = downloadItems.filter((d) => d.mimeType !== TYPE_FOLDER);
    const mediaContentsInCurrentFolder = filesInCurrentFolder.map((f) => ({
        fileId: f.documentRepoId,
        path: [],
        customName: f.name,
    }));
    const apiDownload = apiUrls.downloadAnonymousDA;
    const downloadUrl = apiDownload.url(Service.DA, tenantId);
    const downloadMethod = apiDownload.method;
    const res = await fetchAnonymous({
        url: downloadUrl,
        method: downloadMethod,
        jsonBody: {
            correlationId,
            connectionUUID: connectionId,
            mediaContents: [].concat(mediaContentsFromDescendants, mediaContentsInCurrentFolder),
        },
    });
    return res;
};
export const anonymousGetBoxDocumentData = async (fileId, accessCode) => {
    const { docId, token } = await fetchAnonymous({
        url: apiUrls.getMcrDocumentId.url(fileId, accessCode),
        method: apiUrls.getMcrDocumentId.method,
    });
    const tokenDoc = await fetchAnonymous({
        url: apiUrls.getMcrDocumentToken.url(docId),
        method: apiUrls.getMcrDocumentToken.method,
        headers: {
            wardaanonymoustoken: token,
        },
    });
    return {
        docId,
        token: tokenDoc,
    };
};
export const anonymousGetMediaInfoDA = async (fileId, accessCode) => {
    try {
        const { url, method } = apiUrls.getRenditionPublic;
        const firmedUrl = await fetchAnonymous({
            url: url(accessCode, fileId, RENDITIONS.image_identify) + "?redirect=false",
            method,
        });
        const res = await fetch(firmedUrl, { method });
        const resJson = await res.json();
        let resImage = null;
        if (Array.isArray(resJson)) {
            resImage = resJson[0].image;
        }
        else {
            resImage = resJson.image;
        }
        if (!resImage)
            throw "err";
        return {
            sizeInBytes: resImage.filesize,
            dimensions: `${resImage.geometry.width} x ${resImage.geometry.height} px`,
        };
    }
    catch {
        return null;
    }
};
export const anonymousSearchFiles = async ({ area, hrch, prnt, searchMode, searchEsFolder, searchEsMedia, }) => {
    try {
        const accessCode = getAccessCode();
        const { url, method } = apiUrls.searchFiles;
        const payload = {
            accessCodeToken: accessCode,
            searchMode,
            folder: searchEsFolder,
            media: searchEsMedia,
            parentFolder: undefined,
            hierarchyId: undefined,
        };
        if (searchMode === SearchMode.DEFAULT &&
            hrch !== undefined &&
            prnt !== undefined) {
            payload.parentFolder = {
                hierarchyId: hrch,
                folderId: prnt,
            };
        }
        if (searchMode === SearchMode.HIERARCHY) {
            payload.hierarchyId = hrch;
        }
        const res = await fetchAnonymous({
            url: url(),
            method,
            jsonBody: payload,
        });
        return normalizeMediaLibrary(area, res);
    }
    catch (err) {
        const errMsg = await getErrorMsg(err);
        return { ...SEARCH_FILES_RESULT_ERROR, error: errMsg };
    }
};
export const anonymousDownloadMassive = async ({ correlationId, connectionId, hrch, idsMedia, idsFolder, }) => {
    if (!connectionId) {
        return { success: false, message: "Missing 'websocketId'" };
    }
    if (!correlationId) {
        return { success: false, message: "Missing 'correlationId'" };
    }
    try {
        const { url, method } = apiUrls.downloadMassive;
        const message = await fetchAnonymous({
            url: url(),
            method,
            jsonBody: {
                correlationId,
                connectionUUID: connectionId,
                hierarchyId: hrch,
                folders: idsFolder,
                files: idsMedia,
            },
        });
        return { success: true, message };
    }
    catch (err) {
        const message = await getErrorMsg(err);
        return { success: false, message };
    }
};
export const getViewerImgSrcFirmed = async (sourceUrl) => {
    const sourceUrlFirmed = await fetchAnonymous({
        url: sourceUrl,
        method: "GET",
    });
    return sourceUrlFirmed;
};
export const getViewerBoxData = async (fileId) => {
    const apiDocId = apiUrls.boxDocumentId;
    const { docId } = await fetchAnonymous({
        url: apiDocId.url(fileId),
        method: apiDocId.method,
    });
    const apiDocToken = apiUrls.boxDocumentToken;
    const token = await fetchAnonymous({
        url: apiDocToken.url(docId),
        method: apiDocToken.method,
    });
    return {
        docId,
        token,
    };
};
export const getFilePath = async (area, file) => {
    const accessCode = getAccessCode();
    const { url, method } = apiUrls.searchFiles;
    const hrch = file.data.hierarchyId;
    const prnt = file.data.parentFolderId;
    const res = await fetchAnonymous({
        url: url(),
        method,
        jsonBody: {
            accessCodeToken: accessCode,
            searchMode: SearchMode.DEFAULT,
            parentFolder: {
                hierarchyId: hrch,
                folderId: prnt,
            },
            folder: { from: 0, size: 0 },
            media: { from: 0, size: 0 },
        },
    });
    const normLocation = normalizeElasticSrc(res.folderLocation);
    return composeFolderPath(area, normLocation.items);
};
